import { Component, Input, OnInit, HostBinding, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SidebarService } from '../sidebar.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Menu, MenuItems } from '../../../../shared/menu-items/menu-items';
import { AuthService } from '../../../../security/auth.service';
import { IUserWithToken } from '../../../../models/IUserWithToken';
import { IUser } from '../../../../models/IUser';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-item.component.html',
  styleUrls: [],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuItemComponent implements OnInit {
  expanded: boolean;
  user: IUser;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: Menu;
  @Input() depth: number;

  constructor(
    public authService: AuthService,
    public menuItems: MenuItems,
    public navService: SidebarService,
    private route: ActivatedRoute,
    public router: Router) {

    if (this.depth === undefined) {
      this.depth = 0;
    }

    this.user = authService.getCurrentUser();
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.state && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.state}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  onItemSelected(item: Menu) {
    if (!item.children || !item.children.length) {
      if (item.type == 'link')
        this.router.navigate([item.state], { queryParams: item.params, queryParamsHandling: null });
      if (item.type == 'function') {
        this.router.navigate([item.state], { queryParams: item.params, queryParamsHandling: null}); 
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  canShow(item: Menu): boolean {
    return item.canShow(item, this.user);
  }
}
