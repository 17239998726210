import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertService } from "../../shared/alert/alert.service";
import * as Enumerable from "linq-es2015";
import { IValidationMessage } from '../../models/IValidationMessage';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  constructor(private alertService: AlertService) { }

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any, language?: string) {

    let configs = [];
    configs['it'] = {
      'required': 'Il campo è obbligatorio',
      'email': 'Il campo ' + validatorName + ' deve contenere un indirizzo e-mail valido',
      'invalidpassword': `La password deve contenere almeno ${validatorValue.requiredLength} caratteri e contenere un numero.`,
      'minlength': `Lunghezza minima ${validatorValue.requiredLength}`,
      'invalidmatch': 'Il valore del campo password e il valore del campo conferma password devono corrispondere',
      "pattern": 'Il valore del campo non è corretto',
      "min": `Valore minimo ${validatorValue.min}`,
      'servererror': validatorValue
    };

    configs['en'] = {
      'required': 'This field is required',
      'email': 'The ' + validatorName + ' must contain a valid email address',
      'invalidpassword': 'Password must be at least 6 characters long, and contain a number.',
      'minlength': `Minimum length ${validatorValue.requiredLength}`,
      'invalidmatch': 'The password and confirm password must match',
      "pattern": 'The value of the field is incorrect',
      "min": `Minimum value ${validatorValue.min}`,
      'servererror': validatorValue
    };
    let l = [];
    if (language && language != "")
      l = configs[language];
    else
      l = configs['en'];

    return l[validatorName.toLowerCase()];
  }

  static password(control: FormControl) {

    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&amp;*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }

  static match(controlName: string, matchingControlName: string) {

    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.invalidMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ invalidMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  mapValidationServerMessage(error: IValidationMessage, form: FormGroup) {
    if (form == null) {
      if (error.validations != null) {
        if (Enumerable.from(error.validations).Any())
          this.alertService.error(error.validations.map(val => val.message).join(', '));
      } else {
        this.alertService.error(error.message);
      }
      return;
    }
    if (!error.validations) {
      let formControl = form.get('id');
      if (formControl) {
        formControl.setErrors({
          serverError: error.message
        });
      } else
        this.alertService.error(error.message);
      return;
    }
    Enumerable.asEnumerable(error.validations).ToArray().forEach((item, i) => {
      let formControl = form.get(item['fieldName']);
      if (formControl) {
        formControl.setErrors({
          serverError: item['message']
        });
        error.validations.splice(i, 1);
      }
    });
    if (Enumerable.from(error.validations).Any()) {
      let formControl = form.get('id');
      if (formControl) {
        formControl.setErrors({
          serverError: error.validations.map(val => val.message).join(', ')
        });
        error.validations = [];
      }
    }
    if (Enumerable.from(error.validations).Any())
      this.alertService.error(error.validations.map(val => val.message).join(', '));
  }

  clearValidationMessage() {
    this.alertService.clear();
  }
}

