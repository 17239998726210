
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpModule, Http } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';

import { AppRoutes } from './app.routing';

// i18n support
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ORIGIN_URL } from './shared/constants/baseurl.constants';
import { REQUEST } from './shared/constants/request';

import { RegisterComponent } from "./containers/register/register.component";
import { LoginComponent } from "./containers/login/login.component";
import { CurrentUserInfoComponent } from './components/current-user-info/current-user-info.component';
import { AppComponent } from './app.component';

import { AuthGuard } from './security/auth.guard';
import { AuthService } from "./security/auth.service";
import { HttpService } from "./shared/http.service";
import { LanguageComponent } from './shared/language/language.component';
import { LanguageService } from './shared/language/language.service';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { AlertModule } from "./shared/alert/alert.module";
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { AppConfigModule } from './shared/app-config.module';
import { RenewalComponent } from './containers/renewal-user-token/renewal.component';
import { ChangePasswordComponent } from './containers/change.password.component/change.password.component';
import { ForgotPasswordComponent } from './containers/forgot.password.component/forgot.password.component'
import { MaterialComponentsModule } from './components/material-component/material.module';
import { TimeSheetComponent } from './components/timesheet/timesheet.component';
import { TimeSheetService } from './components/timesheet/timesheet.service';

import { OptionsScrollDirective } from './shared/options-scroll.directive';
import { MaxValueValidatorDirective } from './shared/max-value-validator';
import { MinValueValidatorDirective } from './shared/min-value-validator';
import { MatPaginatorIntl, MAT_DATE_LOCALE, DateAdapter } from '@angular/material';
import { MatPaginatorIntlCro } from './shared/paginator-intl-translation';
import { ConfirmationDialogComponent } from './shared/alert-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { UserService } from './security/user.service';
import { ChangeLanguageComponent } from './shared/language/language.modal.component';
import { MenuItemComponent } from './layouts/full/sidebar/menu-item/menu-item.component';
import { SidebarService } from './layouts/full/sidebar/sidebar.service';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { ProfileComponent } from './containers/profile/profile.component';
import { FileUploadModule } from 'ng2-file-upload';
import { WebcamModule } from 'ngx-webcam';
import { BroadcastService } from './shared/broadcast.service';
import { HomeComponent } from './components/home/home.component';
import { BlankZeroPipe, NumberFormatPipe, OffsetDatePipe } from './shared/pipes';
import { TableTimeSheetDetailComponent } from './components/timesheet/detail/table.timesheet.detail.component';
import { AccordionTimeSheetDetailComponent } from './components/timesheet/detail/accordion.timesheet.detail.component';
import { TabsTimeSheetDetailComponent } from './components/timesheet/detail/tabs.timesheet.detail.component';
import { ViewDetailSelectorComponent } from './components/timesheet/detail/timesheet.view.selector.component';
import { TimeSheetDetailComponent } from './components/timesheet/detail/timesheet.detail.base.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { AdditionalCostsDialogComponent } from './components/timesheet/detail/additional.costs.edit.component';
import { CameraInteractionComponent } from './components/timesheet/detail/camera/camera.interaction.component';
import { TimeZoneService } from './shared/timezone-service'
import { CustomDateAdapter } from './shared/custom.date.adapter';
import { MaterialElevationDirective } from './shared/material-elevation.directive';
import { RequestPageExportWorkSheet, ExportWoorkSheetComponent } from './components/timesheet/export-worksheet/export.worksheet.component';


registerLocaleData(localeIt, 'it-IT', localeItExtra);

export function createTranslateLoader(http: HttpClient, baseHref) {
  // Temporary Azure hack
  if (baseHref === null && typeof window !== 'undefined') {
    baseHref = window.location.href;
  }
  // i18n files are in `src/assets/i18n/`
  return new TranslateHttpLoader(http, `./assets/i18n/`, '.json');
}

export function getOriginUrl() {
  return window.location.origin;
}

export function getRequest() {
  // the Request object only lives on the server
  return { cookie: document.cookie };
}

const providers = [
  { provide: 'TimeZoneService', useClass: TimeZoneService }
]

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    HomeComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    RegisterComponent,
    LoginComponent,
    CurrentUserInfoComponent,
    LanguageComponent,
    ChangeLanguageComponent,
    RenewalComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    TimeSheetComponent,
    TimeSheetDetailComponent,
    ViewDetailSelectorComponent,
    TableTimeSheetDetailComponent,
    AccordionTimeSheetDetailComponent,
    TabsTimeSheetDetailComponent,
    ProfileComponent,
    ConfirmationDialogComponent,
    AdditionalCostsDialogComponent,
    CameraInteractionComponent,
    AlertDialogComponent,
    ExportWoorkSheetComponent,
    RequestPageExportWorkSheet,
    OptionsScrollDirective,
    MenuItemComponent,
    MaxValueValidatorDirective,
    MinValueValidatorDirective,
    NumberFormatPipe,
    OffsetDatePipe,
    MaterialElevationDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    MaterialComponentsModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    AlertModule,
    AppConfigModule,
    TruncateModule,
    FileUploadModule,
    MaterialFileInputModule,
    WebcamModule,
    DigitOnlyModule,
    RouterModule.forRoot(AppRoutes, { enableTracing: false, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
    // i18n support
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, [ORIGIN_URL]]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      // We need this for our Http calls since they'll be using an ORIGIN_URL provided in main.server
      // (Also remember the Server requires Absolute URLs)
      provide: ORIGIN_URL,
      useFactory: (getOriginUrl)
    },
    {
      // We need this for our Http calls since they'll be using an ORIGIN_URL provided in main.server
      // (Also remember the Server requires Absolute URLs)
      provide: ORIGIN_URL,
      useFactory: (getOriginUrl)
    },
    {
      // The server provides these in main.server
      provide: REQUEST,
      useFactory: (getRequest)
    },
    {
      provide: AuthGuard
    },
    {
      provide: AuthService
    },
    {
      provide: UserService
    },
    {
      provide: HttpService
    },
    {
      provide: LanguageService
    },
    {
      provide: TimeSheetService
    },
    {
      provide: SidebarService
    },
    {
      provide: BroadcastService
    },
    {
      provide: BlankZeroPipe
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new MatPaginatorIntlCro();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    {
      provide: 'TimeZoneService',
      useClass: TimeZoneService
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'it-IT'
    },
    //{
    //  provide: DateAdapter,
    //  useClass: CustomDateAdapter
    //}
    
  ],
  entryComponents: [ExportWoorkSheetComponent],
  bootstrap: [AppComponent],
  exports: [NumberFormatPipe, OffsetDatePipe]
})
export class AppModule {

}
