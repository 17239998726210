import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';

@Pipe({
  name: 'blankzero'
})
export class BlankZeroPipe implements PipeTransform {
  transform(value?: number): any {
    if (value && value == 0)
      return '';
    return value;
  }
}

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any): number {
    return this.localeString(value);
  }

  missingOneDecimalCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (x && x.length === 1) return true;
    return false;
  }

  missingAllDecimalsCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (!x) return true;
    return false;
  }

  localeString(nStr) {
    if (nStr === '') return '';
    let x, x1, x2, rgx, y1, y2;
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? ',' + x[1] : '';
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }

    /** If value was inputed by user, it could have many decimals(up to 7)
        so we need to reformat previous x1 results */
    if (x1.indexOf(',') !== -1) {
      y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

      y2 = x1.split(',');
      x = y2[0] + y1;
    } else {
      x = x1 + x2;
      if (this.missingOneDecimalCheck(x)) return x += '0';
      if (this.missingAllDecimalsCheck(x)) return x += ',00';
    }

    return x;
  }
}

@Pipe({
  name: 'offsetDate'
})
@Injectable()
export class OffsetDatePipe extends
  DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var format = "dd/MM/yyyy";
    if (args != undefined && args != null) {
      if (args.format != undefined && args.format != null)
        format = args.format;
    }
    var m = momenttimezone.tz(value, 'Europe/Rome');
    if (m.isValid()) {
      var offset = m.utcOffset() / 60;
      return super.transform(value, format, 'UTC +' + offset.toString());
    } else
      return (value);
  }
}
