import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) return true;
    if (this.authService.tryGetInfoFromStore()) return true;

    this.router.navigate(["/login"], { queryParams: { returnUrl: this.router.url } });
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {

    if (route.queryParams.view == 'administrator') {
      if (this.authService.isLoggedIn()) {
        if (this.authService.tryGetInfoFromStore()) {
          let user = this.authService.getCurrentUser();
          if (!user.timeSheetAdministrator) {
            this.router.navigate(["/home"], { queryParams: { view: 'home' } });
            return false;
          }
        }
      }
    }
    return true;
  }
}
