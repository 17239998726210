import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../shared/app-config.module';
import { ActivatedRoute, Data } from '@angular/router';
import { ITimeSheetLine, ITimeSheetCardModel, ITimeSheetLineDetail, TypeLine, StatusLine, ViewActionContex, UpdateTimeSheetLine } from '../../../models/IMyTimeSheet';
import { TimeSheetService } from '../timesheet.service';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { MatSnackBar, MatDialog, MatAccordion } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../../../shared/validation/validation.service';
import { SelectionModel } from '@angular/cdk/collections';
import { LanguageService } from '../../../shared/language/language.service';
import { AuthService } from '../../../security/auth.service';
import { TimeSheetDetailComponent } from './timesheet.detail.base.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OffsetDatePipe } from '../../../shared/pipes';

@Component({
  selector: 'accordion-timesheet-detail',
  templateUrl: './accordion.timesheet.detail.component.html',
  styleUrls: ['../timesheet.component.scss'],
  providers: [OffsetDatePipe]
})

export class AccordionTimeSheetDetailComponent extends TimeSheetDetailComponent implements AfterViewInit {

  step = -1;
  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public route: ActivatedRoute,
    public timeSheetService: TimeSheetService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public validationService: ValidationService,
    public languageService: LanguageService,
    public dialog: MatDialog,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public offsetDatePipe: OffsetDatePipe) {

    super(route, timeSheetService, snackBar, translateService, validationService, languageService, dialog, authService, fb, sanitizer, config, offsetDatePipe);

  }
  
  ngAfterViewInit() {
    this.accordion.closeAll();
  }

  addLine() {
    this.loading.next(true);
    this.accordion.closeAll();
    this.timeSheetService.addLine(this.timeSheet.no, TypeLine.Job).subscribe((updateTimeSheetLine: UpdateTimeSheetLine) => {
      if (updateTimeSheetLine) {
        if (!this.timeSheet.timeSheetLineSubForm)
          this.timeSheet.timeSheetLineSubForm = [];
        this.timeSheet.timeSheetLineSubForm.push(updateTimeSheetLine.timeSheetLineSubForm);
        this.timeSheet.timeSheetDetailLine.push(updateTimeSheetLine.timeSheetDetailLine[0]);
        this.setStep(this.timeSheet.timeSheetLineSubForm.length - 1);
      }
    }).add(() => { this.loading.next(false); });
  }

  prevRecord() {
    this.loading.next(true);
    this.timeSheetService.getPrevRecord(this.timeSheet.no, this.timeSheet.resource_No).subscribe(r => {
      if (r) {
        this.timeSheet = r;
        this.setColumns();
        this.accordion.closeAll();
      }
    }).add(() => { this.loading.next(false); });
  }

  nextRecord() {
    this.loading.next(true);
    this.timeSheetService.getNextRecord(this.timeSheet.no, this.timeSheet.resource_No).subscribe(r => {
      this.onAfterGetTimeSheet(r);
    }).add(() => {
      this.loading.next(false);
    });
  }

  isAllSelectedRows() {
    const numSelected = this.selection.selected.length;
    const numRows = this.timeSheet.timeSheetLineSubForm.length;
    return numSelected === numRows;
  }

  masterToggleRows() {
    this.isAllSelectedRows() ?
      this.selection.clear() :
      this.timeSheet.timeSheetLineSubForm.forEach((row) => {
        this.selection.select(<any>row);
      });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

}
