import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../../../security/auth.service';

@Component({
  selector: 'detail-view-selector',
  template: `<div [ngSwitch]="type">
    <input type="hidden" [(ngModel)]="type" />
    <accordion-timesheet-detail *ngSwitchCase="'0'" name="default"></accordion-timesheet-detail>
    <tabs-timesheet-detail *ngSwitchCase="'1'" name="week"></tabs-timesheet-detail>
    <table-timesheet-detail *ngSwitchCase="'desktop'" name="desktop"></table-timesheet-detail>
  </div>`
})
export class ViewDetailSelectorComponent implements OnInit {
  @Input() type: number;
  @Input() name: string;

  constructor(private deviceService: DeviceDetectorService, private authService: AuthService) {
  }

  ngOnInit(): void {
    var user = this.authService.getCurrentUser();
    this.type = user.viewMode;
  }
  
}
