import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DirtyComponent } from './dirty-component';
import { MatDialog } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogComponent } from '../alert-dialog/confirmation-dialog.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {

  constructor(public dialog: MatDialog, public translateService: TranslateService) {
  }

  canDeactivate(
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: this.translateService.instant('CONFIRM_HEADER'),
          message: this.translateService.instant('UNSAVED_FORM'),
          buttonText: {
            ok: this.translateService.instant('YES_BUTTON'),
            cancel: this.translateService.instant('NO_BUTTON')
          }
        }
      });
      return dialogRef.afterClosed().pipe(
        // map the dialog result to a true/false indicating whether
        // the route can deactivate
        map(result => result === true)
      );

    } else {
      return of(true);
    }
  }

}
