export class IPaginator<T> extends Array<T> {
  public static readonly DEFAULT_PAGE_SIZE: number = 10;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  bookmarkFirstKey: string;
  bookmarkLastKey: string;

  constructor(pgIx?: number, pgSize?: number, tot?: number, items?: T[]) {
    super();
    this.pageIndex = pgIx ? pgIx : 0;
    this.pageSize = pgSize ? pgSize : 0;
    this.totalCount = tot ? tot : 0;
    if (items && items.length > 0) {
      this.push(...items);
      if (items && items.length) {
        var first = items[0];
        var last = items[items.length - 1];
        if (first)
          this.bookmarkFirstKey = (<any>last).key;
        if (last)
          this.bookmarkLastKey = (<any>last).key;
      }
    }
  }
}
