import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../security/auth.service';
import { IUser } from '../../models/IUser';

export interface Menu {
  state: string;
  params?: {};
  name: string;
  type: string;
  icon: string;
  children?: Menu[];
  active?: boolean;
  canShow(item: Menu, user: IUser): boolean;
}

const MENUITEMS = <Menu[]>[
  {
    state: 'home',
    params: { 'view': 'home' },
    name: 'Home',
    type: 'link',
    icon: 'home',
    canShow: (item: Menu) => { return true; }
  },
  {
    state: 'timesheets', params: { 'view': 'all' }, name: 'TIMESHEET_VIEW', type: 'link', icon: 'web', canShow: (item: Menu, user: IUser) => { return true; }, 
    children: [
      { state: 'timesheets', params: { 'view': 'open' }, name: 'OPENED_VIEW', type: 'link', canShow: (item: Menu, user: IUser) => { return true; } },
      { state: 'timesheets', params: { 'view': 'inprogress' }, name: 'INPROGRESS_VIEW', type: 'link', canShow: (item: Menu, user: IUser) => { return true; } },
      { state: 'timesheets', params: { 'view': 'submitted' }, name: 'SUBMITTED_VIEW', type: 'link', canShow: (item: Menu, user: IUser) => { return true; }  },
      { state: 'timesheets', params: { 'view': 'rejected' }, name: 'REJECTED_VIEW', type: 'link', canShow: (item: Menu, user: IUser) => { return true; }  },
      { state: 'timesheets', params: { 'view': 'approved' }, name: 'APPROVED_VIEW', type: 'link', canShow: (item: Menu, user: IUser) => { return true; }  },
      //{ state: 'timesheets', params: { 'view': 'approvalrequest' }, name: 'APPROVAL_REQUEST_VIEW', type: 'link', canShow: (item: Menu, user: IUser) => { return true; } },
      //{ state: 'timesheets', params: { 'view': 'administrator' }, name: 'ADMINISTRATOR_VIEW', type: 'link', canShow: (item: Menu, user: IUser) => { return user.timeSheetAdministrator; } }
    ]
  },
  {
    state: 'exportworksheet',
    params: { 'view': 'exportworksheet' },
    name: 'EXPORT_WORK_SHEET',
    type: 'function',
    icon: 'receipt',
    canShow: function (item) { return true; }
  },
  //{ state: 'button', type: 'link', name: 'Buttons', icon: 'crop_7_5' },
  //{ state: 'grid', type: 'link', name: 'Grid List', icon: 'view_comfy' },
  //{ state: 'lists', type: 'link', name: 'Lists', icon: 'view_list' },
  //{ state: 'menu', type: 'link', name: 'Menu', icon: 'view_headline' },
  //{ state: 'tabs', type: 'link', name: 'Tabs', icon: 'tab' },
  //{ state: 'stepper', type: 'link', name: 'Stepper', icon: 'web' },
  //{
  //  state: 'expansion',
  //  type: 'link',
  //  name: 'Expansion Panel',
  //  icon: 'vertical_align_center'
  //},
  //{ state: 'chips', type: 'link', name: 'Chips', icon: 'vignette' },
  //{ state: 'toolbar', type: 'link', name: 'Toolbar', icon: 'voicemail' },
  //{
  //  state: 'progress-snipper',
  //  type: 'link',
  //  name: 'Progress snipper',
  //  icon: 'border_horizontal'
  //},
  //{
  //  state: 'progress',
  //  type: 'link',
  //  name: 'Progress Bar',
  //  icon: 'blur_circular'
  //},
  //{
  //  state: 'dialog',
  //  type: 'link',
  //  name: 'Dialog',
  //  icon: 'assignment_turned_in'
  //},
  //{ state: 'tooltip', type: 'link', name: 'Tooltip', icon: 'assistant' },
  //{ state: 'snackbar', type: 'link', name: 'Snackbar', icon: 'adb' },
  //{ state: 'slider', type: 'link', name: 'Slider', icon: 'developer_mode' },
  //{
  //  state: 'slide-toggle',
  //  type: 'link',
  //  name: 'Slide Toggle',
  //  icon: 'all_inclusive'
  //}
];

@Injectable()
export class MenuItems {

  constructor(
    public translate: TranslateService,
    public authService: AuthService) { }

  getMenuitem(): Menu[] {
    return MENUITEMS;
  }

  getTranslationItem(name: string): string {
    return this.translate ? this.translate.instant(name) : name;
  }

}
