import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationService } from './validation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'validation-message',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationComponent {
  @Input() control: AbstractControl;
  
  constructor(public translate: TranslateService) { }

  get errorMessage() {
    if (this.control && this.control.errors) {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName], this.translate.currentLang);
      }
      }
    }
    return null;
  }
}
