import {Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepicker } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { TimeSheetService } from '../timesheet.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IValidationMessage } from '../../../models/IValidationMessage';
import { ValidationService } from '../../../shared/validation/validation.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'export-worksheet',
  template: '<div></div>',
})

export class ExportWoorkSheetComponent {

  constructor(public dialog: MatDialog) {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestPageExportWorkSheet, {
      width: '500px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
      window.history.go(-1);
    });
  }
}

@Component({
  selector: 'request-page-export-worksheet',
  templateUrl: 'export.worksheet.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RequestPageExportWorkSheet {

  loading = new BehaviorSubject<boolean>(false);
  date = new FormControl(moment(), Validators.required);
  year: number;
  month: number;

  constructor(
    public dialogRef: MatDialogRef<RequestPageExportWorkSheet>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router,
    public timeSheetService: TimeSheetService,
    public validationService: ValidationService) {
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.month = ctrlValue;
    datepicker.close();
  }

  close(): void {
    this.dialogRef.close();
    //this.router.navigate(["/home"], { queryParams: { view: 'home' } });
  }

  download(): void {
    this.validationService.clearValidationMessage();
    const ctrlValue = moment(this.date.value);
    this.year = ctrlValue.year();
    this.month = ctrlValue.month() + 1;
    this.loading.next(true);
    this.timeSheetService.getExcelWorkShett(this.year, this.month)
      .pipe(finalize(() => { this.loading.next(false); }))
      .subscribe(r => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
          window.navigator.msSaveOrOpenBlob(r.contentByteArray, r.name);
        } else { // chrome
          const byteCharacters = atob(r.content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: r.mimeType });
          const url = window.URL.createObjectURL(blob);
          // window.open(url);
          const link = document.createElement('a');
          link.href = url;
          link.download = r.name;
          link.click();
        }
      }, error => {
        let obj = <IValidationMessage>{};
        error._body ? obj = JSON.parse(error._body) : obj = error;
        this.validationService.mapValidationServerMessage(obj, null);
      });      
  }
}

export interface DialogData {
  animal: string;
  name: string;
}
