import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent {
  color = <ThemePalette>'accent';
  checked = false;
  disabled = false;
}
