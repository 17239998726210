import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent, TranslationChangeEvent, DefaultLangChangeEvent } from '@ngx-translate/core';
import { AppConfig, APP_CONFIG } from '../app-config.module';
import { LanguageService } from './language.service';
import { ILanguageView } from '../../models/ILanguageView';
import * as Enumerable from "linq-es2015";
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'language',
  templateUrl: './language.component.html'
})
export class LanguageComponent implements OnInit {
  languages: any[];
  selected: string;

  constructor(
    public translate: TranslateService,
    public languageService: LanguageService,
    private dateAdapter: DateAdapter<any>,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  ngOnInit() {
    this.languages = this.languageService.getSupportedLanguages();
    let storageLang = sessionStorage.getItem(this.config.storageKeyLanguage);
    if (storageLang && storageLang != null && storageLang != "") {
      this.translate.use(storageLang);
      this.dateAdapter.setLocale(storageLang);
      this.translate.reloadLang(storageLang);
      this.selected = storageLang;
    }
    else {
      this.translate.use(this.config.defaultLanguage);
      this.dateAdapter.setLocale(this.config.defaultLanguage);
      this.translate.reloadLang(this.config.defaultLanguage);
      this.selected = this.config.defaultLanguage;
    }
  }

  useLanguage(language) {
    if (language)
      if (<any>language) {
        this.translate.use(language);
        this.dateAdapter.setLocale(language);
        this.translate.reloadLang(language);
        sessionStorage.setItem(this.config.storageKeyLanguage, language);
      }
  }

  getTranslation(language) {
    if (language.value)
      return this.translate.instant(language.value.toUpperCase());
    return "";
  }
}
