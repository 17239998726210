import { Injectable } from '@angular/core';

import { Moment } from 'moment';
import * as momenttimezone from 'moment-timezone';

@Injectable()
export class TimeZoneService {
  public tenantTimeZone: string;

  constructor() {
    this.tenantTimeZone = 'Europe/Rome';
  }

  public setTenantTimeZone(tenantTz: string) {
    this.tenantTimeZone = tenantTz;
  }

  public utcToTenant(utcDateTime: Moment): Moment {
    return momenttimezone.tz(utcDateTime, this.tenantTimeZone);
  }

  public utcToTenantString(utcDateTime: Moment, format: string): string {
    format = momenttimezone.tz.guess() === this.tenantTimeZone ? format : format + 'Z';
    return momenttimezone.tz(utcDateTime, this.tenantTimeZone).format(format);
  }

  public utcToTenantStringDate(utcDateTime: Moment, format: string): string {
    format = momenttimezone.tz.guess() === this.tenantTimeZone ? format : format;
    return momenttimezone.tz(utcDateTime, this.tenantTimeZone).format(format);
  }

  public tenantToUtc(tenantDateTime: Moment): Moment {
    return momenttimezone(tenantDateTime).utc();
  }
}
