import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './containers/login/login.component';
import { RegisterComponent } from './containers/register/register.component';
import { AuthGuard } from './security/auth.guard';
import { RenewalComponent } from './containers/renewal-user-token/renewal.component';
import { TimeSheetComponent } from './components/timesheet/timesheet.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { HomeComponent } from './components/home/home.component';
import { ForgotPasswordComponent } from './containers/forgot.password.component/forgot.password.component';
import { DirtyCheckGuard } from './shared/dirty/dirty.check.guard';
import { TabsTimeSheetDetailComponent } from './components/timesheet/detail/tabs.timesheet.detail.component';
import { AccordionTimeSheetDetailComponent } from './components/timesheet/detail/accordion.timesheet.detail.component';
import { ExportWoorkSheetComponent } from './components/timesheet/export-worksheet/export.worksheet.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'material',
        loadChildren: () => import('./components/material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'timesheets',
        component: TimeSheetComponent,
      },
      {
        path: "timesheets/edit/week",
        component: TabsTimeSheetDetailComponent,
        canDeactivate: [DirtyCheckGuard]
      },
      {
        path: "timesheets/edit/default",
        component: AccordionTimeSheetDetailComponent,
        canDeactivate: [DirtyCheckGuard]
      },
      {
        path: "profile",
        component: ProfileComponent
      }
      //,{
      //  path: "timesheet/:no",
      //  component: DetailComponent
      //}
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'renewalusertoken',
    component: RenewalComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: "exportworksheet",
    component: ExportWoorkSheetComponent
  },
  {
    path: 'logout',
    component: AppHeaderComponent,
    canDeactivate: [DirtyCheckGuard]
  }
  //Implemtentata funzionalità per consentire il controllo prima del logout
];
