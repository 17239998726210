import { Component, OnInit, ViewChild } from '@angular/core';
import { TimeSheetService } from '../timesheet/timesheet.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IMyActivity } from '../../models/IMyTimeSheet';
import { AuthService } from '../../security/auth.service';
import { MatRipple } from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading = new BehaviorSubject<boolean>(false);
  activities = <IMyActivity>{};
  approvalAdministrator: boolean;

  constructor(
    private timeSheetService: TimeSheetService,
    private authService: AuthService
  ) {

    let user = this.authService.getCurrentUser();
    if (user != null)
      this.approvalAdministrator = user.timeSheetAdministrator;
  }

  ngOnInit() {
    this.loading.next(true);
    this.timeSheetService.getMyActivities()
      .pipe(finalize(() => {
        this.loading.next(false);
      }))
      .subscribe(r => {
        this.activities = r;
      });
  }

}
