import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ValidationService } from './validation/validation.service';
import { ValidationComponent } from './validation/validation.component';
import { MetadataService } from './metadata/metadata.service';


@NgModule({
  imports: [CommonModule],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ValidationComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ValidationComponent
   ],
  providers: [
    MenuItems,
    ValidationService,
    MetadataService]
})
export class SharedModule { }
