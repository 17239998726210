import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';

// i18n support
import { TranslateService } from '@ngx-translate/core';
import { REQUEST } from './shared/constants/request';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(public translate: TranslateService, @Inject(REQUEST) private request, @Inject(LOCALE_ID) protected localeId: string) {
  //  // this language will be used as a fallback when a translation isn't found in the current language
  //  translate.setDefaultLang('en');
  //  // the lang to use, if the lang isn't available, it will use the current loader to get them
  //  translate.use('en');

    translate.addLangs(["en", "it"]);
    translate.setDefaultLang('it');

    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
  }

  ngOnDestroy(): void {

  }
  ngOnInit(): void {

  }
}
