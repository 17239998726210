import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of, Observer } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { TimeSheetService } from './timesheet.service';
import { IPaginator } from '../../models/IPaginator';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export class TimeSheetDataSource<T> implements DataSource<T>, Observer<IPaginator<T>> {
 
  private items = new BehaviorSubject<IPaginator<T>>(new IPaginator<T>());
  private loading = new BehaviorSubject<boolean>(false);
  private err = new BehaviorSubject<any>({});

  paginator: MatPaginator;
  pageEvent?: PageEvent;
  bookmarkFirstKey: string;
  bookmarkLastKey: string;
  items$ = this.items.asObservable();
  loading$ = this.loading.asObservable();
  error$ = this.err.asObservable();

  closed = false;

  constructor(private timeSheetService: TimeSheetService) {
  }

  next(results: IPaginator<T>): void {
    if (this.paginator) {
      this.paginator.length = results.totalCount;
      this.bookmarkFirstKey = results.bookmarkFirstKey;
      this.bookmarkLastKey = results.bookmarkLastKey;
    }
    this.items.next(results);
  }

  error(errr: any): void {
    this.err.next(errr);
  }

  complete(): void {
    this.loading.next(false);
  }

  connect(_collectionViewer: CollectionViewer): Observable<IPaginator<T>> {
    this.closed = false;
    return this.items$;
  }

  disconnect(_collectionViewer: CollectionViewer): void {
    this.closed = true;
    this.items.complete();
    this.loading.complete();
    this.err.complete();
  }

  protected preLoad(): void {
    this.err.next({});
    this.loading.next(true);
  }

  resetBookmark() {
    this.bookmarkFirstKey = null;
    this.bookmarkLastKey = null;
  }

  setPaginator(pgntr: MatPaginator, pageEvent?: PageEvent): void {
    this.paginator = pgntr;
    this.pageEvent = pageEvent;
  }

  loadTimeSheets(resourceNo: string,
    sortDirection: string,
    selectedView: string,
    pageIndex: number,
    pageSize: number) {

    this.loading.next(true);

    let bookmark = "";
    let prevPageIndex = 0;
    if (pageIndex > 0) {
      if (this.pageEvent) {
        prevPageIndex = this.pageEvent.previousPageIndex;
        if (this.pageEvent.pageIndex >= this.pageEvent.previousPageIndex)
          bookmark = this.bookmarkLastKey;
        else
          bookmark = this.bookmarkFirstKey;
      }
    }
    this.timeSheetService.findTimeSheets(resourceNo, sortDirection, selectedView, pageIndex, prevPageIndex, pageSize, bookmark)
      .pipe(catchError(() => of([])), finalize(() => this.loading.next(false)))
      .subscribe(result => {
        this.next((<any>result));
      })
  }

}

