import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, APP_CONFIG } from '../app-config.module';
import { HttpService } from '../http.service';
import * as Enumerable from "linq-es2015";
import { ILanguageView } from '../../models/ILanguageView';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  constructor(
    public translate: TranslateService,
    public httpService: HttpService,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  getSupportedLanguages(): ILanguageView[] {
    let languages = <ILanguageView[]>([]);
    let configLanguages = this.translate.getLangs();
    let current = this.translate.currentLang;
    Enumerable.from(configLanguages).ToArray().forEach((language) => {
      languages.push({
        value: language,
        viewValue: this.translate.instant(language.toUpperCase()),
        selected: language == current
      });
    });
    return languages;
  }

  getLanguage() {
    var lang = sessionStorage.getItem(this.config.storageKeyLanguage);
    if (lang != undefined && lang != null)
      return lang;
    this.translate.use(this.config.defaultLanguage);
    this.translate.reloadLang(this.config.defaultLanguage);
    sessionStorage.setItem(this.config.storageKeyLanguage, this.config.defaultLanguage);
    return this.config.defaultLanguage;
  }

  useLanguage(language) {
    if (language) {
      return this.httpService.get(this.config.apiEndpoint + '/user/changelanguage/' + language).map((r) => {
        if (<boolean><unknown>r === true) {
          this.translate.use(language);
          this.translate.reloadLang(language);
          sessionStorage.setItem(this.config.storageKeyLanguage, language);
        }
        return r;
      });
    }
  }
}
