
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "../../security/auth.service";
import { ValidationService } from '../../shared/validation/validation.service';
import { APP_CONFIG, AppConfig } from '../../shared/app-config.module';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/language/language.service';
import { IApplicationConfiguration } from '../../models/IApplicatinConfiguration';
import { ICompany } from '../../models/ICompany';
import { AlertService } from '../../shared/alert/alert.service';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: './forgot.password.component.html',
  styleUrls: ['./forgot.password.style.css']
})

export class ForgotPasswordComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  forgotPasswordForm: FormGroup;
  submitted = false;
  pUsername: string;
  language: string;
  eMail: string;
  showLogin: boolean = false;
  configuration: IApplicationConfiguration;
  filteredCompanies: ICompany[];
  regexUrl = ".*"  //Needed (ngPattern surrounds with ^ and $)
    + "(?=^.{6,}$)"       //At least 6 Characters
    + "(?=[^\\d]*\\d)"    //At least one digit
    + "(?=[^\\W]*\\W)"    //At least one special character
    + "(?=[^A-Z]*[A-Z])"  //At least one uppercase character
    + "(?=[^a-z]*[a-z])"  //At least one lowercase character
    + ".*";    //Needed (ngPattern surrounds with ^ and $)

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private validationService: ValidationService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    public languageService: LanguageService,
    @Inject(APP_CONFIG) config: AppConfig) {

    var configuration = localStorage.getItem(config.storageApplicationConfiguration);
    if (configuration != null && configuration != "")
      this.configuration = JSON.parse(configuration);
    else
      this.configuration = <IApplicationConfiguration>{};
  }

  ngOnInit() {
    this.language = this.languageService.getLanguage();

    this.route.queryParams.subscribe(params => {
      this.pUsername = params['username'];
      this.eMail = params['email'];
    });

    this.forgotPasswordForm = this.formBuilder.group({
      id: [''],
      username: [this.pUsername, Validators.required],
      eMail: [this.eMail, [Validators.required, Validators.email]],
      language: [this.language, [Validators.required]],
      companyName: [this.configuration.companyName, Validators.required],
      serverInstance: [this.configuration.serverInstance, [Validators.required, Validators.pattern(this.regexUrl)]],
    });
    this.authService.logout();

    var serverInstance = this.forgotPasswordForm.controls.serverInstance.value;
    if (serverInstance == undefined || serverInstance == null || serverInstance == "") {
      this.loading = true;
      this.authService.getSetup().subscribe(
        data => {
          this.forgotPasswordForm.controls['serverInstance'].setValue(data.base_Url_Web_Service);
          this.loading = false;
        },
        error => {
          let obj = JSON.parse(error._body);
          this.validationService.mapValidationServerMessage(obj, this.forgotPasswordForm);
          this.loading = false;
        });
    } 
  }

  onSubmit(model) {
    this.submitted = true;
    this.forgotPasswordForm.controls['id'].updateValueAndValidity();
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.forgotPassword(model);
  }

  forgotPassword(model) {
    this.loading = true;
    this.authService.forgotPassword(model.value)
      .subscribe(
        () => {
          this.loading = false;
          this.alertService.success(this.translateService.instant('SEND_REQUEST_FORGOT_SUCCESFULLY'));
          this.showLogin = true;
        },
        error => {
          let obj = JSON.parse(error._body);
          this.validationService.mapValidationServerMessage(obj, this.forgotPasswordForm);
          this.loading = false;
        });
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  onChangeServerInstance() {
    this.forgotPasswordForm.controls.companyName.setValue('');
    this.filteredCompanies = [];
  }

  onFocusCompanyName() {
    this.lookupCompanyName();
  }

  lookupCompanyName() {
    this.alertService.clear();
    this.loading = true;
    var serverInstance = this.forgotPasswordForm.controls.serverInstance.value;
    return this.authService.loadCompanies(serverInstance)
      .pipe(
        finalize(() => {
          this.loading = false;
        }))
      .subscribe((results) => {
        this.filteredCompanies = results;
      }, (error) => {
        var err = error.json();
        this.alertService.error(err.message);
      });
  }
}
