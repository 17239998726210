import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../../security/auth.service";
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../security/user.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ChangeLanguageComponent } from '../../../shared/language/language.modal.component';
import { BroadcastService } from '../../../shared/broadcast.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  changeLanguageDialogRef: MatDialogRef<ChangeLanguageComponent>;
  avatar: any = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private _sanitizer: DomSanitizer,
    userService: UserService,
    private dialog: MatDialog,
    private broadcastService: BroadcastService
  ) {
    userService.getAvatar(authService.user.username).subscribe((result) => {
      if (result != undefined && result != null && result != "")
        this.avatar = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + result);
      else
        this.avatar = null;
    });
    this.broadcastService.subscribe("AVATAR-CHANGE", (newAvatar) => { this.avatar = newAvatar });
  }
  /*
  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
  */
  //Cambiata funzionalità così da gestire il controllo prima del logout
  logout() {
    this.router.navigate(['login'], { queryParams: { logout: true } });
  }

  changeLaguage() {
    this.changeLanguageDialogRef = this.dialog.open(ChangeLanguageComponent);
    this.changeLanguageDialogRef.afterClosed().pipe().subscribe(language => {

    });
  }

  goToProfile() {
    this.router.navigateByUrl('/profile');
  };
}
