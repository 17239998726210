import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeSheetService } from '../timesheet.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import * as Enumerable from "linq-es2015";
import { TranslateService } from '@ngx-translate/core';
import { slideInOutAnimation } from '../../../shared/animations/slide-in-out.animation';
import { IAdditionalCostModel, Work_Type_Use, ITimeSheetLine, IActualScheduledSummaryModel } from '../../../models/IMyTimeSheet';
import { finalize } from 'rxjs/operators';
import { IWorkTypeCode } from '../../../models/IJobReference';
import { IValidationMessage } from '../../../models/IValidationMessage';
import { ValidationService } from '../../../shared/validation/validation.service';
import { ConfirmationDialogComponent } from '../../../shared/alert-dialog/confirmation-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CameraInteractionComponent } from './camera/camera.interaction.component';
import { OffsetDatePipe } from '../../../shared/pipes';

@Component({
  selector: 'additional-costs-dialog',
  templateUrl: 'additional.costs.edit.component.html',
  styleUrls: ['additional.costs.edit.style.less'],
  animations: [slideInOutAnimation],
  providers: [OffsetDatePipe]
})

export class AdditionalCostsDialogComponent implements OnInit  {
  public breakpoint: number; // Breakpoint observer code
  public loading = new BehaviorSubject<boolean>(false);
  public loadingLookup = new BehaviorSubject<boolean>(false);
  public costFormGroup: FormGroup;
  public paramData: any;
  public cost: IAdditionalCostModel;
  public summary: IActualScheduledSummaryModel;
  public wasFormChanged = false;
  public workTypeUse: Work_Type_Use;
  public timeSheetLine: ITimeSheetLine;
  public date: string;
  public title: string;
  public disableQuantity: boolean;
  public disableUnitAmt: boolean;
  public disable: boolean;
  public multipleWebcamsAvailable = false;

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFile() {
    var b64encodedString = this.pdfBase64;
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, 'text/plain');
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }
  } 
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  fileData: File = null;
  previewUrl: any = null;
  pdfBase64: any = '';
  Namepdf: any = null;
  fileUrl;



  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AdditionalCostsDialogComponent>,
    private fb: FormBuilder,
    public timeSheetService: TimeSheetService,
    public translateService: TranslateService,
    public validationService: ValidationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private offsetDatePipe: OffsetDatePipe) {

    dialogRef.disableClose = true;


        this.paramData = data;
    this.cost = data.cost;
    this.workTypeUse = data.workTypeUse;
    this.timeSheetLine = data.timeSheetLine;
    this.date = data.date;
    this.disable = data.disable;
    
    if (this.workTypeUse == Work_Type_Use.Allowance) {
      this.title = this.translateService.instant('ALLOWANCE');
      this.disableQuantity = true;
      this.disableUnitAmt = true;
    }
    if (this.workTypeUse == Work_Type_Use.Expense) {
      this.title = this.translateService.instant('EXPENSE');
      this.disableQuantity = false;
      this.disableUnitAmt = false;
    }
    if (this.workTypeUse == Work_Type_Use.Own_Car_Usage) {
      this.title = this.translateService.instant('OWN_CAR_USAGE');
      this.disableQuantity = false;
      this.disableUnitAmt = true;
    }

    if (this.timeSheetLine.editable === false) {
      this.disableQuantity = true;
      this.disableUnitAmt = true;
    }
  }


  ngOnInit() {
    this.initializeForm(); 
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code

    if (this.cost.picture_Name != null && this.cost.picture_Name != '') {
      this.loading.next(true);
      this.timeSheetService.getPictureCost(this.cost.entry_No)
        .pipe(finalize(() => { this.loading.next(false); }))
        .subscribe(r => {
          this.costFormGroup.patchValue({
            fileAttr: r.name
          });
          if (r.mimeType == 'image') {
            this.costFormGroup.patchValue({
              picture_Name: r.name
            });
            this.Namepdf = null;
            this.previewUrl = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + r.content);
          } 
          if (r.mimeType == 'pdf') {
            this.costFormGroup.patchValue({
              picture_Name: r.name,
            });
            this.Namepdf = r.name;
            this.pdfBase64 = r.content;
            this.previewUrl = null;
          }

        }, error => {
            let obj = <IValidationMessage>{};
            error._body ? obj = JSON.parse(error._body) : obj = error;
            this.validationService.mapValidationServerMessage(obj, this.costFormGroup);
          });
    }
    this.costFormGroup.get("quantity").valueChanges.subscribe(selectedValue => {
      this.updateTotalAmount();
    });
    this.costFormGroup.get("work_Type_Code").valueChanges.subscribe(selectedValue => {
      this.updateTotalAmount();
    });
    this.costFormGroup.get("unit_Amount_LCY").valueChanges.subscribe(selectedValue => {
      this.updateTotalAmount();
    });
  }

  initializeForm() {
    this.wasFormChanged = false;
    if (this.cost == null) {
      this.cost = <IAdditionalCostModel>{
        job_No: this.timeSheetLine.job_No,
        job_Task_No: this.timeSheetLine.job_Task_No,
        posting_Date: this.date,
        resource_No: this.timeSheetLine.resource_No,
        time_Sheet_No: this.timeSheetLine.time_Sheet_No,
        time_Sheet_Line_No: this.timeSheetLine.line_No,
        work_Type_Use: this.workTypeUse
      }
      if (this.workTypeUse == Work_Type_Use.Allowance)
        this.cost.quantity = 1;
    }

    if (this.cost) {
      this.costFormGroup = this.fb.group({
        key: [this.cost.key],
        entry_No: [this.cost.entry_No],
        job_No: [this.cost.job_No],
        job_Task_No: [this.cost.job_Task_No],
        posting_Date: [{ value: this.offsetDatePipe.transform(this.date), disabled: true }, Validators.required],
        quantity: [{ value: this.cost.quantity, disabled: this.disableQuantity || this.disable }, [Validators.required, Validators.min(0.1)]],
        resource_No: [this.cost.resource_No, Validators.required],
        time_Sheet_No: [this.cost.time_Sheet_No, Validators.required],
        time_Sheet_Line_No: [this.cost.time_Sheet_Line_No, Validators.required],
        work_Type_Code: [{ value: this.cost.work_Type_Code, disabled: this.timeSheetLine.editable || this.disable }, Validators.required],
        work_Type_Use: [this.cost.work_Type_Use, Validators.required],
        unit_of_Measure_Code: [{ value: this.cost.unit_of_Measure_Code, disabled: true }, Validators.required],
        unit_Amount_LCY: [{ value: this.cost.unit_Amount_LCY, disabled: this.disableUnitAmt || this.disable }, [Validators.required, Validators.min(0.1)]],
        amount_LCY: [{ value: this.cost.amount_LCY, disabled: true }, Validators.required],
        description: [{ value: this.cost.description, disabled: this.timeSheetLine.editable || this.disable }, Validators.required],
        fileAsBase64: [],
        picture_Name: [],
        Namepdf: [],
        file_base64: [],
        fileExt : '',
      });
    }
    if (this.timeSheetLine.editable == false) {
      this.costFormGroup.get('description').disable();
      this.costFormGroup.get('work_Type_Code').disable();
    } else {
      this.costFormGroup.get('work_Type_Code').enable();
      this.costFormGroup.get('description').enable();
    }
  }

  fileProgress(fileInput: any) {
    this.loading.next(true);
    if (fileInput && fileInput.files) {
      this.fileData = <File>fileInput.files[0];
      this.clear(fileInput);
      //this.clearFileInput(document.getElementById("file"));
      this.preview();
    }
    this.loading.next(false);
  }

  clear(element) {
    element.value = "";
  }
  /*
  clearFileInput(ctrl) {
    ctrl.value = null;
  }
  */
  preview() {
   
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*|application\/pdf/) == null ) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      if (mimeType != 'application/pdf') {
        this.previewUrl = reader.result;
        this.Namepdf = '';
        var base64 = (<string>reader.result).replace(/^data:.+;base64,/, '');
        this.costFormGroup.patchValue({
          picture_Name: this.fileData.name,
          fileAsBase64: base64
        });
      } else {
        this.previewUrl = '';
        this.pdfBase64 = (<string>reader.result).replace(/^data:.+;base64,/, '');
        this.costFormGroup.patchValue({
          picture_Name: this.fileData.name,
          fileAsBase64: this.pdfBase64
        });
        this.Namepdf = this.fileData.name;
      }
    }
  }

  public deletePicture() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: this.translateService.instant('CONFIRM_HEADER'),
        message: this.translateService.instant('DELETE_PICTURE_QUESTION'),
        buttonText: {
          ok: this.translateService.instant('YES_BUTTON'),
          cancel: this.translateService.instant('NO_BUTTON')
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading.next(true);
        this.timeSheetService.deletePictureCost(this.cost.entry_No)
          .pipe(
            finalize(() => {
              this.loading.next(false);
            }))
          .subscribe(result => {
            if (result) {
              this.costFormGroup.patchValue({
                picture_Name: null,
                fileAsBase64: null
              });
              this.previewUrl = null;
              this.Namepdf = null;
              this.pdfBase64 = null;
            }
            //this.clearFileInput(document.getElementById("file"));
          }, error => {
            this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
            let obj = <IValidationMessage>{};
            error._body ? obj = JSON.parse(error._body) : obj = error;
            this.validationService.mapValidationServerMessage(obj, this.costFormGroup);
          });
      }
    });
  }

  private updateTotalAmount() {
    var numToFixedDp = Number((this.costFormGroup.get("unit_Amount_LCY").value * this.costFormGroup.get("quantity").value)).toFixed(2);
    this.costFormGroup.patchValue({
      amount_LCY: Number(numToFixedDp)
    });
  }

  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  public onSubmitFormGroup(): void {
    this.validationService.clearValidationMessage();
    this.markAsDirty(this.costFormGroup);
    if (this.costFormGroup.valid) {
      this.updateCost();
    }
  }
  
  private updateCost() {
    this.loading.next(true);
    this.costFormGroup.value.posting_Date = this.date;
    this.costFormGroup.value.unit_Amount_LCY = this.costFormGroup.get('unit_Amount_LCY').value;
    this.timeSheetService.updateCost(this.costFormGroup.value).subscribe(r => {
      this.showMessage(this.translateService.instant('SAVE_SUCCESS_MESSAGE'), '', false);
      this.costFormGroup.patchValue(r);
      this.costFormGroup.patchValue({
        posting_Date: this.offsetDatePipe.transform(this.date)
      });
      this.cost = r;
      this.wasFormChanged = false;
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.costFormGroup);
    }).add(() => {
      this.loading.next(false);
    });
  }

  onCloseDialog() {
    if (this.wasFormChanged === true) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: this.translateService.instant('CONFIRM_HEADER'),
          message: this.translateService.instant('UNSAVED_FORM'),
          buttonText: {
            ok: this.translateService.instant('YES_BUTTON'),
            cancel: this.translateService.instant('NO_BUTTON')
          }
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed)
          this.dialogRef.close(true);
      });
    } else {
      this.dialogRef.close(true);
    }
  }

  public delete() {
    this.validationService.clearValidationMessage();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: this.translateService.instant('CONFIRM_HEADER'),
        message: this.translateService.instant('DELETE_ITEMS_QUESTION'),
        buttonText: {
          ok: this.translateService.instant('YES_BUTTON'),
          cancel: this.translateService.instant('NO_BUTTON')
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading.next(true);
        if (this.cost.entry_No && this.cost.entry_No > 0) {
          this.timeSheetService.deleteCost(this.cost.entry_No)
            .pipe(
              finalize(() => {
                this.loading.next(false);
              }))
            .subscribe(result => {
              if (result)
                this.dialogRef.close(result);
            }, error => {
              this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
              let obj = <IValidationMessage>{};
              error._body ? obj = JSON.parse(error._body) : obj = error;
              this.validationService.mapValidationServerMessage(obj, this.costFormGroup);
            });
        } else {
          this.dialogRef.close(true);
        }
      }
    });
  }

  private showMessage(message: string, action: string, isError: boolean) {
    let _class = 'mat-accent';
    if (isError && isError == true)
      _class = 'mat-warn'
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['mat-toolbar', _class],
      direction: "ltr",
      horizontalPosition: "center",
    });
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  lookupWorkTypeCodeCost(row: FormGroup, value: string) {
    if (value == undefined || value == null)
      value = "";
    this.loadingLookup.next(true);
    return this.timeSheetService.findWorkTypeCodes(value.toLowerCase(), this.workTypeUse, this.timeSheetLine.time_Sheet_No, this.timeSheetLine.line_No)
      .pipe(finalize(() => {
        this.loadingLookup.next(false);
      }))
      .subscribe((results) => {
        (<any>row).filteredWorkTypesCodes = results;
      });
  }

  onFocusWorkTypeCodeCost(row: FormGroup) {
    if (row)
      this.lookupWorkTypeCodeCost(row, "");
  }

  selectWorkTypeCodeCost(row: FormGroup, value: string) {
    let list: IWorkTypeCode[] = (<any>row).filteredWorkTypesCodes;
    if (list) {
      var option = <IWorkTypeCode>Enumerable.from(list).FirstOrDefault(x => (<IWorkTypeCode>x).code == value);
      if (option) {
        row.patchValue({
          description: option.description,
          unit_of_Measure_Code: option.unit_of_Measure_Code,
          unit_Amount_LCY: option.resource_Cost,
          amount_LCY: option.resource_Cost * this.cost.quantity
        });
      }
    }
  }

  public takePicture() {
    const dialogRef = this.dialog.open(CameraInteractionComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.previewUrl = result;
        var base64 = (<string>result).replace(/^data:.+;base64,/, '');
        
        this.costFormGroup.patchValue({
          picture_Name: ((this.cost != null && this.cost.description != null) ? this.cost.description : this.costFormGroup.controls.description.value),
          fileAsBase64: base64
        }); 
        this.formChanged();
      }
    });
  }
}
