import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeSheetService } from '../../timesheet.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { slideInOutAnimation } from '../../../../shared/animations/slide-in-out.animation';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { DomSanitizer } from '@angular/platform-browser';
import { IAdditionalCostModel, IActualScheduledSummaryModel, Work_Type_Use, ITimeSheetLine } from '../../../../models/IMyTimeSheet';
import { ValidationService } from '../../../../shared/validation/validation.service';

@Component({
  selector: 'camera-interaction-dialog',
  templateUrl: 'camera.interaction.component.html',
  animations: [slideInOutAnimation],
})
export class CameraInteractionComponent implements OnInit  {
  public breakpoint: number; // Breakpoint observer code
  public loading = new BehaviorSubject<boolean>(false);
  public webcamErrors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  public multipleWebcamsAvailable = false;
  private trigger: Subject<void> = new Subject<void>();
  public showWebcam: boolean = true;
  public take: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CameraInteractionComponent>,
    private fb: FormBuilder,
    public timeSheetService: TimeSheetService,
    public translateService: TranslateService,
    public validationService: ValidationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer) {

    dialogRef.disableClose = true;
  }

  ngOnInit() {
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  public handleInitError(error: WebcamInitError): void {
    this.webcamErrors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.showWebcam = false;
    this.take = true;
  }

  public useSnapshot() {
    if (this.take === true)
      this.dialogRef.close(this.webcamImage.imageAsDataUrl);
  }

  public newSnapshot() {
    this.showWebcam = true;
    this.take = false;
    this.webcamImage = null;
  }

  public closeDialog() {
    this.dialogRef.close(null);
  }
}

