import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, APP_CONFIG } from '../app-config.module';
import { LanguageService } from './language.service';
import { ILanguageView } from '../../models/ILanguageView';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'languagemodal',
  templateUrl: './language.modal.component.html'
})
export class ChangeLanguageComponent implements OnInit {
  languages: any[];
  selected: string;
  loading = new BehaviorSubject<boolean>(false);

  constructor(
    public translate: TranslateService,
    public languageService: LanguageService,
    private dialogRef: MatDialogRef<ChangeLanguageComponent>,
    @Inject(APP_CONFIG) private config: AppConfig) {

    this.languages = <ILanguageView[]>([]);
    this.selected = translate.currentLang;
    this.languages = this.languageService.getSupportedLanguages();
  }

  ngOnInit(): void {
    
  }

  useLanguage(language) {
    this.loading.next(true);
    if (language)
      if (<any>language) {
        this.languageService.useLanguage(language).subscribe((r) => {
          this.loading.next(false);
        });
      }
  }
}
