import { Component, OnInit, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig } from "../../../shared/app-config.module";
import { ActivatedRoute } from "@angular/router";
import { TimeSheetService } from "../timesheet.service";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { MatSnackBar, MatDialog, MatTableDataSource } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "../../../shared/validation/validation.service";
import { LanguageService } from '../../../shared/language/language.service';
import { AuthService } from "../../../security/auth.service";
import { ViewActionContex, ITimeSheetCardModel, ITimeSheetLine, TypeLine, ITimeSheetLineDetail, StatusLine, ITimeSheetGroup, Work_Type_Use, IAdditionalCostModel, IActualScheduledSummaryModel } from "../../../models/IMyTimeSheet";
import { finalize } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import * as moment from 'moment';
import * as Enumerable from "linq-es2015";
import { IJobTaskReference, IAbsenceCode, IJobReference } from '../../../models/IJobReference';
import { ConfirmationDialogComponent } from "../../../shared/alert-dialog/confirmation-dialog.component";
import { IValidationMessage } from "../../../models/IValidationMessage";
import { ViewMode } from "../../../models/IProfile";
import { DomSanitizer } from "@angular/platform-browser";
import { AdditionalCostsDialogComponent } from "./additional.costs.edit.component";
import { OffsetDatePipe } from "../../../shared/pipes";

@Component({
})

export class TimeSheetDetailComponent extends DataSource<ITimeSheetLine> implements OnInit {
    
  language: string;
  viewActionContex: ViewActionContex;
  loading = new BehaviorSubject<boolean>(false);
  timeSheet: ITimeSheetCardModel = <ITimeSheetCardModel>{};
  selection = new SelectionModel<ITimeSheetLine>(true, []);
  dateArray = [];
  columnRecords: Date[] = [new Date, new Date, new Date, new Date, new Date];
  isLoading = false;
  form: FormGroup = this.fb.group({ timeSheetLines: this.fb.array(<ITimeSheetLine[]>([])) });
  dataSourceLines = new BehaviorSubject<ITimeSheetLine[]>([]);
  isDirty = false;
  disableNegativeQuantity = false;
  minValueQuantity = 0;
  disableAbsences = false;
  enableResAdditionCosts = false;
  disableAllowance = false;
  displayedColumnsAdditionalCosts: string[] = ['description', 'amount'];
  
  constructor(
    public route: ActivatedRoute,
    public timeSheetService: TimeSheetService,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public validationService: ValidationService,
    public languageService: LanguageService,
    public dialog: MatDialog,
    public authService: AuthService,
    public fb: FormBuilder,
    public sanitizer: DomSanitizer,
    public config: AppConfig,
    public offsetDatePipe: OffsetDatePipe) {
      super();
      this.language = this.languageService.getLanguage();
    this.viewActionContex = ViewActionContex.MemberAction;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: { id: string; view: string; }) => {
      if (params.id != undefined && params.id != null && params.id != "")
        this.getTimeSheet(params.id);
      if (params.view != undefined && params.view != null && params.view != "") {
        if (params.view === 'approvalrequest' || params.view === 'administrator') {
          this.viewActionContex = ViewActionContex.ManagerActions;
          let currentUser = this.authService.getCurrentUser();
          if (currentUser)
            if (currentUser.timeSheetVerifier == true)
              this.viewActionContex = ViewActionContex.ManagerVerifierActions;
        }
      }
    });
    this.authService.getSetup().subscribe(
      setup => {
        if (setup) {
          this.disableNegativeQuantity = setup.disable_Negative_Quantity;
          if (setup.disable_Absences === true)
            this.disableAbsences = true;
          if (setup.enable_Res_Addition_Costs)
            this.enableResAdditionCosts = true;
          if (setup.disable_Allowance_Insert)
            this.disableAllowance = true;
        }
      },
      error => {
        this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
        let obj = <IValidationMessage>{};
        error._body ? obj = JSON.parse(error._body) : obj = error;
        this.validationService.mapValidationServerMessage(obj, this.form);
      });
  }

  connect(collectionViewer: import("@angular/cdk/collections").CollectionViewer): import("rxjs").Observable<ITimeSheetLine[] | readonly ITimeSheetLine[]> {
    return <any>this.timeSheet.timeSheetLineSubForm;
  }
  disconnect(collectionViewer: import("@angular/cdk/collections").CollectionViewer): void {
   
  }

  getTimeSheet(timeSheetNo: string) {
    this.loading.next(true);
    this.timeSheetService.getTimeSheet(timeSheetNo)
      .pipe(finalize(() => { this.loading.next(false); }))
      .subscribe(r => { this.onAfterGetTimeSheet(r); },
        error => {
          let obj = <IValidationMessage>{};
          error._body ? obj = JSON.parse(error._body) : obj = error;
          this.validationService.mapValidationServerMessage(obj, this.form);
      });
  }

  onAfterGetTimeSheet(timeSheet: ITimeSheetCardModel) {
    if (timeSheet) {
      this.timeSheet = timeSheet;
      this.dataSourceLines.next(this.timeSheet.timeSheetLineSubForm);
      this.setColumns();
      this.clearSelection();

      this.timeSheet.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
        line.isDirty = false;
      });

      this.timeSheet.timeSheetLineGroups = [];
      if (this.timeSheet.summary && this.timeSheet.summary.dateArray) {
        for (var j = 0; j < this.timeSheet.summary.dateArray.length; j++) {
          if (this.timeSheet.timeSheetLineGroups[j] == undefined || this.timeSheet.timeSheetLineGroups[j] == null)
            this.timeSheet.timeSheetLineGroups[j] = <ITimeSheetGroup><unknown>{ timeSheetLines: <ITimeSheetLine><unknown>[] };
        }
      }

      var user = this.authService.getCurrentUser();
      if (user.viewMode == ViewMode.Week) { 
        if (this.timeSheet.timeSheetLineSubForm) {
          for (var j = 0; j < this.timeSheet.summary.dateArray.length; j++) {
            var date = this.timeSheet.summary.dateArray[j];
            Enumerable.from(this.timeSheet.timeSheetLineSubForm).ToArray().forEach((timeSheetLine: ITimeSheetLine) => {
              if (timeSheetLine.cost == null)
                timeSheetLine.cost = { time_Sheet_No: timeSheetLine.time_Sheet_No, line_No: timeSheetLine.line_No };
              var existDetails = Enumerable.from(this.timeSheet.timeSheetDetailLine).Any(x =>
                (<ITimeSheetLineDetail>x).time_Sheet_No == timeSheetLine.time_Sheet_No &&
                (<ITimeSheetLineDetail>x).time_Sheet_Line_No == timeSheetLine.line_No &&
                this.offsetDatePipe.transform((<ITimeSheetLineDetail>x).date) == this.offsetDatePipe.transform(date));
              if (existDetails === true) {
                this.timeSheet.timeSheetLineGroups[j].timeSheetLines.push(timeSheetLine);
              } else {
                if (this.offsetDatePipe.transform((<ITimeSheetLine>timeSheetLine).date) == this.offsetDatePipe.transform(date)) {
                  this.timeSheet.timeSheetLineGroups[j].timeSheetLines.push(timeSheetLine);
                }
              }
            });
            }
          }
      }
      this.updateTotals();
      }
  }

  onLookupJob(row: ITimeSheetLine, value: string) {
    if (value == undefined || value == null) {
      value = "";
      row.job_Task_No = "";
      row.description = "";
    }
    row.filteredJobTasks = [];
    this.isLoading = true;
    var resourceNo = this.authService.user.resourceNo;
    return this.timeSheetService.findJobs(this.timeSheet.no, value.toLowerCase(), resourceNo, null, 1, null, null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        row.filteredJobs = results;
      });
  }

  onKeyUpJob(row: ITimeSheetLine, searchValue: string) {
    if (searchValue == null || searchValue == "") {
      row.job_Task_No = "";
      row.description = "";
      row.filteredJobTasks = [];
    }
  }

  onFocusLookupJob(row: ITimeSheetLine) {
    if (row) {
      var value = "";
      if (row.job_No != undefined && row.job_No != null) {
        value = row.job_No;
      }
      this.onLookupJob(row, "");
    }
  }

  lookupJobTasks(row: ITimeSheetLine, value: string) {
    if (row.job_No == undefined || row.job_No == null || row.job_No == "") {
      row.filteredJobTasks = [];
      row.job_Task_No = "";
      row.description = "";
      return;
    }
    if (value == undefined || value == null)
      value = "";
    this.isLoading = true;
    var resourceNo = this.authService.user.resourceNo;
    return this.timeSheetService.findJobTasks(this.timeSheet.no, row.job_No, value.toLowerCase(), resourceNo, null, 1, null, null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        row.filteredJobTasks = results;
      });
  }

  lookupWorkTypeCode(row: ITimeSheetLine, value: string) {
    if (value == undefined || value == null)
      value = "";
    this.isLoading = true;
    return this.timeSheetService.findWorkTypeCodes(value.toLowerCase(), null, null, null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        row.filteredWorkTypesCodes = results;
      });
  }

  onFocusWorkTypeCode(row: ITimeSheetLine) {
    if (row) {
      var value = "";
      if (row.work_Type_Code != undefined && row.work_Type_Code != null)
        value = row.work_Type_Code;
      this.lookupWorkTypeCode(row, "");
    }
  }

  lookupAbsenceCode(row: ITimeSheetLine, value: string) {
    if (value == undefined || value == null)
      value = "";
    this.isLoading = true;
    return this.timeSheetService.findAbsencesCodes(this.timeSheet.no, value.toLowerCase(), null, 1, null, null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        row.filteredAbsencesCodes = results;
      });
  }

  onFocusLookupAbsenceCode(row: ITimeSheetLine) {
    if (row) {
      var value = "";
      if (row.cause_of_Absence_Code != undefined && row.cause_of_Absence_Code != null)
        value = row.cause_of_Absence_Code;
      row.cause_of_Absence_Code = value;
      this.lookupAbsenceCode(row, "");
    }
  }

  onFocusLookupJobTask(row: ITimeSheetLine) {
    if (row) {
      var value = "";
      if (row.job_Task_No != undefined && row.job_No != null)
        value = row.job_Task_No;
      this.lookupJobTasks(row, "");
    }
  }

  selectJob(row: ITimeSheetLine, value: any) {
    if (value == undefined || value == null)
      return;
    row.job_Task_No = "";
    row.work_Type_Code = "";
    row.extDocMandatory = false;
    row.chargeable = false;
    row.work_Type_Code = "";
    row.description = "";
    var job = Enumerable.from(row.filteredJobs).FirstOrDefault(x => (<IJobReference>x).no == value);
    if (job != null) {
      row.jobDescription = (<IJobReference>job).description;
    }
  }

  selectJobTask(row: ITimeSheetLine, value: any) {
    if (value == undefined || value == null)
      return;
    if (row.filteredJobTasks) {
      let list: IJobTaskReference[] = row.filteredJobTasks;
      if (list) {
        var option = <IJobTaskReference>Enumerable.from(list).FirstOrDefault(x => (<IJobTaskReference>x).job_Task_No == value);
        if (option && option.description) {
          row.description = option.description;
        }
        if (option && option.job_Task_No) {
          var line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x => (<any>x).line_No == row.line_No);
          if (line)
            line.job_Task_No = option.job_Task_No;
          row.job_Task_No = option.job_Task_No;
          row.extDocMandatory = option.extDocMandatory;
          row.chargeable = option.chargeable;
          row.work_Type_Code = option.defaultWorkTypeCode;
        }
      }
    }
  }

  selectAbsenceCode(row: ITimeSheetLine, value: any) {
    if (value == undefined || value == null)
      return;
    if (row.cause_of_Absence_Code) {

      let list: IAbsenceCode[] = row.filteredAbsencesCodes;
      if (list) {
        var option = <IAbsenceCode>Enumerable.from(list).FirstOrDefault(x => (<IAbsenceCode>x).code == value);
        if (option && option.code) {
          row.description = option.description;
          //this.timeSheetService.findAbsencesCodes(this.timeSheet.no, row.cause_of_Absence_Code, null, 1, null, null).subscribe((results) => {
          //  if (results[0])
          //    row.description = results[0].description;
          //});
        }

      }
    }
    //this.updateView();
  }

  selectContent(fieldNo: number, row: ITimeSheetLine, $event) {
    if (row['field' + fieldNo] === 0)
      row['field' + fieldNo] = null;
  }

  changeType(row: ITimeSheetLine, type: number) {
    if (type == TypeLine.Job) {
      if (row.cause_of_Absence_Code != "") {
        row.cause_of_Absence_Code = "";
        row.description = "";
        this.emptyLine(row);
      }
    }

    if (type == TypeLine.Absence) {
      row.chargeable = false;
      if (row.job_No != "") {
        row.job_No = "";
        row.job_Task_No = "";
        row.description = "";
        this.emptyLine(row);
      }
    }
  }

  onChangeDetailDay(line: ITimeSheetLine, fieldNo: number) {
    let date = this.columnRecords[fieldNo - 1];
    var detail = <ITimeSheetLineDetail>Enumerable.from(this.timeSheet.timeSheetDetailLine).FirstOrDefault(x =>
      (<ITimeSheetLineDetail>x).time_Sheet_No == line.time_Sheet_No &&
      (<ITimeSheetLineDetail>x).time_Sheet_Line_No == line.line_No &&
      this.offsetDatePipe.transform((<ITimeSheetLineDetail>x).date) == date);
    if (detail) {
      detail.type = line.type;
      detail.date = this.offsetDatePipe.transform(date);
      if (fieldNo == 1)
        detail.quantity = line.field1;
      if (fieldNo == 2)
        detail.quantity = line.field2;
      if (fieldNo == 3)
        detail.quantity = line.field3;
      if (fieldNo == 4)
        detail.quantity = line.field4;
      if (fieldNo == 5)
        detail.quantity = line.field5;
      if (fieldNo == 6)
        detail.quantity = line.field6;
      if (fieldNo == 7)
        detail.quantity = line.field7;
      detail.bS005_Chargeable_Quantity = detail.quantity;
    } else {
      detail = <ITimeSheetLineDetail>{};
      detail.time_Sheet_No = line.time_Sheet_No;
      detail.time_Sheet_Line_No = line.line_No;
      detail.date = this.offsetDatePipe.transform(date);
      detail.type = line.type;
      detail.job_No = line.job_No;
      detail.job_Task_No = line.job_Task_No;
      detail.resource_No = this.timeSheet.resource_No;
      if (fieldNo == 1)
        detail.quantity = line.field1;
      if (fieldNo == 2)
        detail.quantity = line.field2;
      if (fieldNo == 3)
        detail.quantity = line.field3;
      if (fieldNo == 4)
        detail.quantity = line.field4;
      if (fieldNo == 5)
        detail.quantity = line.field5;
      if (fieldNo == 6)
        detail.quantity = line.field6;
      if (fieldNo == 7)
        detail.quantity = line.field7;

      detail.bS005_Chargeable = line.chargeable;
      detail.bS005_Chargeable_Quantity = detail.quantity;
      this.timeSheet.timeSheetDetailLine.push(detail);
    }

    if (fieldNo == 1)
      line.chargeableQty1 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 2)
      line.chargeableQty2 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 3)
      line.chargeableQty3 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 4)
      line.chargeableQty4 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 5)
      line.chargeableQty5 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 6)
      line.chargeableQty6 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 7)
      line.chargeableQty7 = detail.bS005_Chargeable_Quantity;

    if (line.type == TypeLine.Absence) {
      line.chargeableQty1 = 0;
      line.chargeableQty2 = 0;
      line.chargeableQty3 = 0;
      line.chargeableQty4 = 0;
      line.chargeableQty5 = 0;
      line.chargeableQty6 = 0;
      line.chargeableQty7 = 0;
    }

    let totalRowQty = 0
    let totalQty: number[] = [];
    Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>
      (<ITimeSheetLineDetail>x).time_Sheet_No == line.time_Sheet_No &&
      (<ITimeSheetLineDetail>x).time_Sheet_Line_No == line.line_No).ToArray().forEach((detail: ITimeSheetLineDetail) => {
      if (detail.quantity)
        totalRowQty += Number(detail.quantity);
    });
    for (var i = 0; i <= this.dateArray.length; i++) {
      totalQty[i] = 0;
      var details = Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>(<ITimeSheetLineDetail>x).time_Sheet_No == this.timeSheet.no).ToArray();
      if (details != null) {
        details.forEach((d: ITimeSheetLineDetail) => {
          var dateDetail = this.offsetDatePipe.transform(d.date);
          if (dateDetail == this.dateArray[i]) {
            if (d.quantity)
                totalQty[i] += Number(d.quantity);
            }
        });
      }
    }
    this.timeSheet.summary.quantities = totalQty;

    this.timeSheet.summary.absenceQty = 0;
    this.timeSheet.summary.totalPresenceQty = 0;
    Enumerable.from(this.timeSheet.timeSheetLineSubForm).ToArray().forEach((l: ITimeSheetLine) => {
      Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>
        (<ITimeSheetLineDetail>x).time_Sheet_No == l.time_Sheet_No &&
        (<ITimeSheetLineDetail>x).time_Sheet_Line_No == l.line_No).ToArray().forEach((detail: ITimeSheetLineDetail) => {
        if (detail.quantity) {
          if (l.type != undefined && l.type != null && l.type == TypeLine.Absence)
            this.timeSheet.summary.absenceQty += Number(detail.quantity);
          this.timeSheet.summary.totalPresenceQty += Number(detail.quantity);
        }
      });
    });
    line.total_Quantity = totalRowQty;
    this.updateTotals();
    line.totalQuantityOnCapacity = this.getHint(fieldNo - 1);
  }

  onChangeChargeableQty(line: ITimeSheetLine, fieldNo: number) {
    let date = this.columnRecords[fieldNo - 1];
    var detail = <ITimeSheetLineDetail>Enumerable.from(this.timeSheet.timeSheetDetailLine).FirstOrDefault(x =>
      (<ITimeSheetLineDetail>x).time_Sheet_No == line.time_Sheet_No &&
      (<ITimeSheetLineDetail>x).time_Sheet_Line_No == line.line_No &&
      this.offsetDatePipe.transform((<ITimeSheetLineDetail>x).date) == date);
    if (detail) {
      detail.type = line.type;
      if (fieldNo == 1)
        detail.bS005_Chargeable_Quantity = line.chargeableQty1;
      if (fieldNo == 2)
        detail.bS005_Chargeable_Quantity = line.chargeableQty2;
      if (fieldNo == 3)
        detail.bS005_Chargeable_Quantity = line.chargeableQty3;
      if (fieldNo == 4)
        detail.bS005_Chargeable_Quantity = line.chargeableQty4;
      if (fieldNo == 5)
        detail.bS005_Chargeable_Quantity = line.chargeableQty5;
      if (fieldNo == 6)
        detail.bS005_Chargeable_Quantity = line.chargeableQty6;
      if (fieldNo == 7)
        detail.bS005_Chargeable_Quantity = line.chargeableQty7;
    } else {
      detail = <ITimeSheetLineDetail>{};
      detail.time_Sheet_No = line.time_Sheet_No;
      detail.time_Sheet_Line_No = line.line_No;
      detail.date = date;
      detail.type = line.type;
      detail.job_No = line.job_No;
      detail.job_Task_No = line.job_Task_No;
      detail.resource_No = this.timeSheet.resource_No;
      if (fieldNo == 1)
        detail.bS005_Chargeable_Quantity = line.chargeableQty1;
      if (fieldNo == 2)
        detail.bS005_Chargeable_Quantity = line.chargeableQty2;
      if (fieldNo == 3)
        detail.bS005_Chargeable_Quantity = line.chargeableQty3;
      if (fieldNo == 4)
        detail.bS005_Chargeable_Quantity = line.chargeableQty4;
      if (fieldNo == 5)
        detail.bS005_Chargeable_Quantity = line.chargeableQty5;
      if (fieldNo == 6)
        detail.bS005_Chargeable_Quantity = line.chargeableQty6;
      if (fieldNo == 7)
        detail.bS005_Chargeable_Quantity = line.chargeableQty7;

      this.timeSheet.timeSheetDetailLine.push(detail);
    }
    detail.bS005_Chargeable = line.chargeable;
    if (fieldNo == 1)
      if (line.field1 == null || line.field1 == 0)
        line.field1 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 2)
      if (line.field2 == null || line.field2 == 0)
        line.field2 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 3)
      if (line.field3 == null || line.field3 == 0)
        line.field3 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 4)
      if (line.field4 == null || line.field4 == 0)
        line.field4 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 5)
      if (line.field5 == null || line.field5 == 0)
        line.field5 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 6)
      if (line.field6 == null || line.field6 == 0)
        line.field6 = detail.bS005_Chargeable_Quantity;
    if (fieldNo == 7)
      if (line.field7 == null || line.field7 == 0)
        line.field7 = detail.bS005_Chargeable_Quantity;
    //if (detail.bS005_Chargeable_Quantity != null && detail.bS005_Chargeable_Quantity != 0)
    //  this.onChangeDetailDay(line, fieldNo);
  }

  onChangeExtDoc(line: ITimeSheetLine, fieldNo: number) {
    let date = this.columnRecords[fieldNo - 1];
    var detail = <ITimeSheetLineDetail>Enumerable.from(this.timeSheet.timeSheetDetailLine).FirstOrDefault(x =>
      (<ITimeSheetLineDetail>x).time_Sheet_No == line.time_Sheet_No &&
      (<ITimeSheetLineDetail>x).time_Sheet_Line_No == line.line_No &&
      this.offsetDatePipe.transform((<ITimeSheetLineDetail>x).date) == date);
    if (detail) {
      detail.type = line.type;
      if (fieldNo == 1)
        detail.bS005_External_Document_No = line.extDoc1;
      if (fieldNo == 2)
        detail.bS005_External_Document_No = line.extDoc2;
      if (fieldNo == 3)
        detail.bS005_External_Document_No = line.extDoc3;
      if (fieldNo == 4)
        detail.bS005_External_Document_No = line.extDoc4;
      if (fieldNo == 5)
        detail.bS005_External_Document_No = line.extDoc5;
      if (fieldNo == 6)
        detail.bS005_External_Document_No = line.extDoc6;
      if (fieldNo == 7)
        detail.bS005_External_Document_No = line.extDoc7;
    } else {
      let newDetail: ITimeSheetLineDetail = {};
      newDetail.time_Sheet_No = line.time_Sheet_No;
      newDetail.time_Sheet_Line_No = line.line_No;
      newDetail.date = date;
      newDetail.type = line.type;
      newDetail.job_No = line.job_No;
      newDetail.job_Task_No = line.job_Task_No;
      newDetail.resource_No = this.timeSheet.resource_No;
      if (fieldNo == 1)
        newDetail.bS005_External_Document_No = line.extDoc1;
      if (fieldNo == 2)
        newDetail.bS005_External_Document_No = line.extDoc2;
      if (fieldNo == 3)
        newDetail.bS005_External_Document_No = line.extDoc3;
      if (fieldNo == 4)
        newDetail.bS005_External_Document_No = line.extDoc4;
      if (fieldNo == 5)
        newDetail.bS005_External_Document_No = line.extDoc5;
      if (fieldNo == 6)
        newDetail.bS005_External_Document_No = line.extDoc6;
      if (fieldNo == 7)
        newDetail.bS005_External_Document_No = line.extDoc7;
      this.timeSheet.timeSheetDetailLine.push(newDetail);
    }
  }

  onChangeNonWorking(line: ITimeSheetLine, event) {
    if (event.checked) {
      line.bS005_Not_Worked_Add_Cost = true;
      this.emptyLine(line);
    } else {
      line.bS005_Not_Worked_Add_Cost = false;
    }
  }

  emptyLine(row: ITimeSheetLine) {
    row.field1 = 0;
    row.field2 = 0;
    row.field3 = 0;
    row.field4 = 0;
    row.field5 = 0;
    row.field6 = 0;
    row.field7 = 0;
    row.chargeableQty1 = 0;
    row.chargeableQty2 = 0;
    row.chargeableQty3 = 0;
    row.chargeableQty4 = 0;
    row.chargeableQty5 = 0;
    row.chargeableQty6 = 0;
    row.chargeableQty7 = 0;
    this.timeSheet.timeSheetDetailLine = Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>
      (<ITimeSheetLineDetail>x).time_Sheet_No == row.time_Sheet_No &&
      (<ITimeSheetLineDetail>x).time_Sheet_Line_No != row.line_No).ToArray();
  }

  delete(): void {
    this.validationService.clearValidationMessage();
    let deletedItems = false;
    if (this.selection) {
      if (this.selection.selected.length <= 0)
        return;

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: this.translateService.instant('CONFIRM_HEADER'),
          message: this.translateService.instant('DELETE_ITEMS_QUESTION'),
          buttonText: {
            ok: this.translateService.instant('YES_BUTTON'),
            cancel: this.translateService.instant('NO_BUTTON')
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.loading.next(true);
          let errors = <IValidationMessage[]>([]);
          if (this.selection) {
            this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
            this.selection.selected.forEach((selected: ITimeSheetLine) => {
              if (selected) {
                let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x => (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No && (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
                (<ITimeSheetLine>line).selected = true;
              }
            });
          }
          let toDeletes = Enumerable.from(this.timeSheet.timeSheetLineSubForm).Where(x => (<ITimeSheetLine>x).selected).ToArray();
          if (toDeletes && toDeletes.length > 0) {
            const t = <ITimeSheetCardModel>{
              no: this.timeSheet.no,
              timeSheetLineSubForm: toDeletes
            };
            this.timeSheetService.delete(this.timeSheet.no, t)
              .pipe(
                finalize(() => {
                  this.loading.next(false);
                  if (Enumerable.from(errors).Any()) {
                    let obj = {
                      validations: errors
                    };
                    this.validationService.mapValidationServerMessage(<any>obj, this.form);
                  } else {
                    if (deletedItems === true) { 
                      this.showMessage(this.translateService.instant('DELETE_SUCCESS_MESSAGE'), '', false);
                      this.updateTotals();
                    }
                  }
                }))
              .subscribe(deletedList => {
                if (deletedList) {
                  Enumerable.from(deletedList).ToArray().forEach((item) => {
                    if ((<any>item).item2 === true) {
                      deletedItems = true;
                      let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x => (<ITimeSheetLine>x).line_No == (<any>item).item1);
                      if (line != null) {
                        this.timeSheet.timeSheetLineSubForm = Enumerable.from(this.timeSheet.timeSheetLineSubForm).Where(x => (<ITimeSheetLine>x).line_No != (<ITimeSheetLine>line).line_No).ToArray();
                        this.timeSheet.timeSheetDetailLine = Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x => (<ITimeSheetLineDetail>x).time_Sheet_Line_No != line.line_No).ToArray();
                        this.timeSheet.timeSheetLineGroups.forEach((group: ITimeSheetGroup) => {
                          if (group.timeSheetLines) {
                            group.timeSheetLines = Enumerable.from(group.timeSheetLines).Where(x => (<ITimeSheetLine>x).line_No != (<ITimeSheetLine>line).line_No).ToArray();
                          }
                        });
                        this.selection.deselect(line);
                        this.updateTotals();
                      }
                    } else {
                      errors.push(<IValidationMessage>{ message: (<any>item).item3 });
                    }
                  });
                }
              }, error => {
                this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
                let obj = <IValidationMessage>{};
                error._body ? obj = JSON.parse(error._body) : obj = error;
                this.validationService.mapValidationServerMessage(obj, this.form);
              });
          }
        } else
           this.loading.next(false);
      });

    }
  }

  updateTotals() {
    var totalQty: number[] = [];
    for (var i = 0; i <= this.dateArray.length; i++) {
      totalQty[i] = 0;
      var details = Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>
        (<ITimeSheetLineDetail>x).time_Sheet_No == this.timeSheet.no &&
        this.offsetDatePipe.transform((<ITimeSheetLineDetail>x).date) == this.dateArray[i]).ToArray();
      if (details != null) {
        details.forEach((d: ITimeSheetLineDetail) => {
          if (d.quantity)
            totalQty[i] += Number(d.quantity);
        });
      }
    }
    this.timeSheet.summary.quantities = totalQty;

    this.timeSheet.summary.absenceQty = 0;
    this.timeSheet.summary.totalPresenceQty = 0;
    this.timeSheet.statusSummary.openQty = 0;
    this.timeSheet.statusSummary.submittedQty = 0;
    this.timeSheet.statusSummary.rejectedQty = 0;
    this.timeSheet.statusSummary.approvedQty = 0;
    this.timeSheet.statusSummary.totalQuantity = 0;
    Enumerable.from(this.timeSheet.timeSheetLineSubForm).ToArray().forEach((l: ITimeSheetLine) => {
      Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>
        (<ITimeSheetLineDetail>x).time_Sheet_No == l.time_Sheet_No &&
        (<ITimeSheetLineDetail>x).time_Sheet_Line_No == l.line_No).ToArray().forEach((detail: ITimeSheetLineDetail) => {
        if (detail.quantity) {
          if (l.type != undefined && l.type != null && l.type == TypeLine.Absence)
            this.timeSheet.summary.absenceQty += Number(detail.quantity);
          this.timeSheet.summary.totalPresenceQty += Number(detail.quantity);
          if (l.status == StatusLine.Open)
            this.timeSheet.statusSummary.openQty += Number(detail.quantity);
          if (l.status == StatusLine.Approved)
            this.timeSheet.statusSummary.approvedQty += Number(detail.quantity);
          if (l.status == StatusLine.Rejected)
            this.timeSheet.statusSummary.rejectedQty += Number(detail.quantity);
          if (l.status == StatusLine.Submitted)
            this.timeSheet.statusSummary.submittedQty += Number(detail.quantity);
          this.timeSheet.statusSummary.totalQuantity += Number(detail.quantity);
        }
      });
    });

    //for (var j = 0; j < this.timeSheet.summary.dateArray.length; j++) {
    //  if (this.timeSheet.timeSheetLineGroups[j]) {
    //    Enumerable.from(this.timeSheet.timeSheetLineGroups[j].timeSheetLines).ToArray().forEach((line: ITimeSheetLine) => {
    //      line.totalQuantityOnCapacity = this.getHint(j);
    //    });
    //  }
    //}
    //Enumerable.from(this.timeSheet.timeSheetLineGroups).ToArray().forEach((group: ITimeSheetGroup) => {
    //  Enumerable.from(group.timeSheetLines).ToArray().forEach((line: ITimeSheetLine) => {
    //    var k = -1;
    //    if (line.field1 != undefined && line.field1 != null)
    //      k = 1;
    //    if (line.field2 != undefined && line.field2 != null)
    //      k = 2;
    //    if (line.field3 != undefined && line.field3 != null)
    //      k = 3;
    //    if (line.field4 != undefined && line.field4 != null)
    //      k = 4;
    //    if (line.field5 != undefined && line.field5 != null)
    //      k = 5;
    //    if (line.field6 != undefined && line.field6 != null)
    //      k = 6;
    //    if (line.field7 != undefined && line.field7 != null)
    //      k = 7;

    //    if (k != -1) { 
    //      var detail = <ITimeSheetLineDetail>Enumerable.from(this.timeSheet.timeSheetDetailLine).FirstOrDefault(x =>
    //        (<ITimeSheetLineDetail>x).time_Sheet_No == this.timeSheet.no &&
    //        (<ITimeSheetLineDetail>x).time_Sheet_Line_No == line.line_No &&
    //        this.offsetDatePipe.transform((<ITimeSheetLineDetail>x).date) == this.columnRecords[k]);
    //      if (detail != null) {
    //        var cpy = this.timeSheet.summary.capacities[k];
    //        line.totalQuantityOnCapacity = detail.quantity.toString() + '/' + cpy.toString();
    //      }
    //    }
    //  });        
    //});

  }

  updateTotalsCosts(summary: IActualScheduledSummaryModel) {
    this.timeSheet.summary.allowancesAmounts = summary.allowancesAmounts;
    this.timeSheet.summary.expensesAmounts = summary.expensesAmounts;
    this.timeSheet.summary.ownCarUsagesAmounts = summary.ownCarUsagesAmounts;
    this.timeSheet.summary.totalAllowanceAmount = summary.totalAllowanceAmount;
    this.timeSheet.summary.totalExpenseAmount = summary.totalExpenseAmount;
    this.timeSheet.summary.totalOwnCarUsageAmount = summary.totalOwnCarUsageAmount;
  }

  saveAll() {
    this.validationService.clearValidationMessage();
    this.loading.next(true);

    this.selection.selected.forEach((selected: ITimeSheetLine) => {
      if (selected) {
        let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x =>
          (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No &&
          (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
        line.selected = true;
      }
    });

    this.timeSheetService.update(this.timeSheet).subscribe(r => {
      this.onAfterGetTimeSheet(r);
      this.showMessage(this.translateService.instant('SAVE_SUCCESS_MESSAGE'), '', false);
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  saveLine(row: ITimeSheetLine, date: Date) {
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    row.date = date;
    this.timeSheetService.updateLine(row).subscribe(r => {
      this.clearSelection();
      row.isDirty = false;
      row.enableCost = false;
      if (r.timeSheetLineSubForm != null)
        row.enableCost = r.timeSheetLineSubForm.enableCost;
      this.updateTotals();
      if (r.summary != null)
        this.timeSheet.summary = r.summary;
      if (r.statusSummary != null)
        this.timeSheet.statusSummary = r.statusSummary;
      this.showMessage(this.translateService.instant('SAVE_SUCCESS_MESSAGE'), '', false);
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  submitSelected() {
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    if (this.selection) {
      this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
      this.selection.selected.forEach((selected: ITimeSheetLine) => {
        if (selected) {
          let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x =>
            (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No &&
            (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
          line.selected = true;
        }
      });
      this.timeSheetService.submitSelected(this.timeSheet).subscribe(r => {
        this.onAfterGetTimeSheet(r);
        this.showMessage(this.translateService.instant('SUBMITTED_SUCCESS_MESSAGE'), '', false);
      }, error => {
        let obj = <IValidationMessage>{};
        error._body ? obj = JSON.parse(error._body) : obj = error;
        this.validationService.mapValidationServerMessage(obj, this.form);
        this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      }).add(() => {
        this.loading.next(false);
      });
    }
  }

  submitSelectedLine(row: ITimeSheetLine, date: Date) {
    this.validationService.clearValidationMessage();
    this.loading.next(true);

    row.date = date;

    this.timeSheetService.submitSelectedLine(row).subscribe(r => {
      this.clearSelection();
      row.isDirty = false;

      //var ts = this.timeSheet;
      //const index = (<any>ts.timeSheetLineSubForm).findIndex(x => (<ITimeSheetLine>x).time_Sheet_No == r.timeSheetNo && (<ITimeSheetLine>x).line_No == r.timeSheetLineSubForm.line_No);
      //ts.timeSheetLineSubForm[index] = r.timeSheetLineSubForm;

      //var details = Enumerable.from(ts.timeSheetDetailLine).Where(x => (<ITimeSheetLineDetail>x).time_Sheet_No == r.timeSheetNo && (<ITimeSheetLineDetail>x).time_Sheet_Line_No == r.timeSheetLineSubForm.line_No && moment((<ITimeSheetLineDetail>x).date).utcOffset('+0100').format('YYYY-MM-DD') != moment(date).utcOffset('+0100').format('YYYY-MM-DD')).ToArray();
      //ts.timeSheetDetailLine = details;
      //Enumerable.from(r.timeSheetDetailLine).ToArray().forEach((x: ITimeSheetLineDetail) => {
      //  ts.timeSheetDetailLine.push(x);
      //});

      //this.onAfterGetTimeSheet(ts);

      const index = (<any>this.timeSheet.timeSheetLineSubForm).findIndex(x => (<ITimeSheetLine>x).time_Sheet_No == r.timeSheetNo && (<ITimeSheetLine>x).line_No == r.timeSheetLineSubForm.line_No);
      this.timeSheet.timeSheetLineSubForm[index] = r.timeSheetLineSubForm;
      Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>
        (<ITimeSheetLineDetail>x).time_Sheet_No == r.timeSheetNo &&
        (<ITimeSheetLineDetail>x).time_Sheet_Line_No == r.timeSheetLineSubForm.line_No &&
        this.offsetDatePipe.transform((<ITimeSheetLineDetail>x).date) == this.offsetDatePipe.transform(date)).ToArray().forEach((det: ITimeSheetLineDetail) => {
          det.status = StatusLine.Submitted;
        });
      this.onAfterGetTimeSheet(this.timeSheet);
      this.updateTotals();

        this.showMessage(this.translateService.instant('SUBMITTED_SUCCESS_MESSAGE'), '', false);
      }, error => {
        let obj = <IValidationMessage>{};
        error._body ? obj = JSON.parse(error._body) : obj = error;
        this.validationService.mapValidationServerMessage(obj, this.form);
        this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      }).add(() => {
        this.loading.next(false);
      });
    
  }

  reopenSelected() {
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    if (this.selection) {
      this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
      this.selection.selected.forEach((selected: ITimeSheetLine) => {
        if (selected) {
          let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x =>
            (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No &&
            (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
          line.selected = true;
        }
      });
    }
    this.timeSheetService.reopenSelected(this.timeSheet).subscribe(r => {
      this.onAfterGetTimeSheet(r);
      this.showMessage(this.translateService.instant('REOPEN_SUCCESS_MESSAGE'), '', false);
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  copyLinesFromPrevTimeSheet() {
    this.validationService.clearValidationMessage();
   
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: this.translateService.instant('CONFIRM_HEADER'),
        message: this.translateService.instant('COPY_FROM_PREVIOUS_TIMESHEET_MESSAGE'),
        buttonText: {
          ok: this.translateService.instant('YES_BUTTON'),
          cancel: this.translateService.instant('NO_BUTTON')
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading.next(true);
        this.timeSheetService.copyLinesFromPrevTimeSheet(this.timeSheet.no).subscribe(r => {
            this.loading.next(true);
            this.getTimeSheet(this.timeSheet.no);
            this.loading.next(false);
          }, error => {
            this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
            let obj = <IValidationMessage>{};
            error._body ? obj = JSON.parse(error._body) : obj = error;
            this.validationService.mapValidationServerMessage(obj, this.form);
          }).add(() => {
            this.loading.next(false);
          });
      }
    });
  }

  verifySelected() {
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    if (this.selection) {
      this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
      this.selection.selected.forEach((selected: ITimeSheetLine) => {
        if (selected) {
          let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x =>
            (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No &&
            (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
          line.selected = true;
        }
      });
      this.timeSheetService.verifySelected(this.timeSheet).subscribe(r => {
        this.onAfterGetTimeSheet(r);
        this.showMessage(this.translateService.instant('VERIFY_SUCCESS_MESSAGE'), '', false);
      }, error => {
        this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
        let obj = <IValidationMessage>{};
        error._body ? obj = JSON.parse(error._body) : obj = error;
        this.validationService.mapValidationServerMessage(obj, this.form);
      }).add(() => {
        this.loading.next(false);
      });
    }
  }

  approveSelected() {
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    if (this.selection) {
      this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
      this.selection.selected.forEach((selected: ITimeSheetLine) => {
        if (selected) {
          let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x =>
            (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No &&
            (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
          line.selected = true;
        }
      });
      this.timeSheetService.approveSelected(this.timeSheet).subscribe(r => {
        this.onAfterGetTimeSheet(r);
        this.showMessage(this.translateService.instant('APPROVE_SUCCESS_MESSAGE'), '', false);
      }, error => {
        this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
        let obj = <IValidationMessage>{};
        error._body ? obj = JSON.parse(error._body) : obj = error;
        this.validationService.mapValidationServerMessage(obj, this.form);
      }).add(() => {
        this.loading.next(false);
      });
    }
  }

  rejectSelected() {
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    if (this.selection) {
      this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
      this.selection.selected.forEach((selected: ITimeSheetLine) => {
        if (selected) {
          let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x =>
            (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No &&
            (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
          line.selected = true;
        }
      });
    }
    this.timeSheetService.rejectSelected(this.timeSheet).subscribe(r => {
      this.onAfterGetTimeSheet(r);
      this.showMessage(this.translateService.instant('REJECT_SUCCESS_MESSAGE'), '', false);
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  openAllowances(timeSheetLine: ITimeSheetLine, date: Date) {
    if (timeSheetLine.cost == null)
      timeSheetLine.cost = { time_Sheet_No: timeSheetLine.time_Sheet_No, line_No: timeSheetLine.line_No };
    timeSheetLine.cost.showAllowances = true;
    timeSheetLine.cost.allowancesLoading = new BehaviorSubject<boolean>(true);
    return this.timeSheetService.getAdditionalCosts(timeSheetLine.time_Sheet_No, timeSheetLine.line_No, Work_Type_Use.Allowance.toString(), date)
      .pipe(finalize(() => {
        timeSheetLine.cost.allowancesLoading.next(false);
      }))
      .subscribe((results) => {
        if (results != null) {
          if (results[0]) {
            timeSheetLine.cost.allowancesFormArray = this.fb.array([]);
            timeSheetLine.cost.allowancesFormGroup = this.fb.group({ costs: timeSheetLine.cost.allowancesFormArray });
            timeSheetLine.cost.allowancesDataSource = new MatTableDataSource(results[0]);
            timeSheetLine.cost.allowancesCosts = [];
            timeSheetLine.bS005_Allowance_Amount_LCY = 0;
            Enumerable.from(results[0]).ToArray().forEach((d: IAdditionalCostModel) => {
              var costFormGroup = this.buildCost(timeSheetLine, d, date)
              timeSheetLine.cost.allowancesFormArray.push(costFormGroup);
              timeSheetLine.cost.allowancesCosts.push(d);
              timeSheetLine.bS005_Allowance_Amount_LCY += d.amount_LCY;
            });
          }
          if (results[1])
            this.updateTotalsCosts(results[1]);
        }
      });
  }
  
  openExpenses(timeSheetLine: ITimeSheetLine, date: Date) {
    if(timeSheetLine.cost == null)
      timeSheetLine.cost = { time_Sheet_No: timeSheetLine.time_Sheet_No, line_No: timeSheetLine.line_No };
    timeSheetLine.cost.showExpenses = true;
    timeSheetLine.cost.expensesLoading = new BehaviorSubject<boolean>(true);
    return this.timeSheetService.getAdditionalCosts(timeSheetLine.time_Sheet_No, timeSheetLine.line_No, Work_Type_Use.Expense.toString(), date)
      .pipe(finalize(() => {
        timeSheetLine.cost.expensesLoading.next(false);
      }))
      .subscribe((results) => {
        if (results != null) {
          if (results[0]) {
            timeSheetLine.cost.expensesFormArray = this.fb.array([]);
            timeSheetLine.cost.expensesFormGroup = this.fb.group({ costs: timeSheetLine.cost.expensesFormArray });
            timeSheetLine.cost.expensesDataSource = new MatTableDataSource(results[0]);
            timeSheetLine.cost.expensesCosts = [];
            timeSheetLine.bS005_Expense_Amount_LCY = 0;
            Enumerable.from(results[0]).ToArray().forEach((d: IAdditionalCostModel) => {
              var costFormGroup = this.buildCost(timeSheetLine, d, date)
              timeSheetLine.cost.expensesFormArray.push(costFormGroup);
              timeSheetLine.cost.expensesCosts.push(d);
              timeSheetLine.bS005_Expense_Amount_LCY += d.amount_LCY;
            });
          }
          if (results[1])
            this.updateTotalsCosts(results[1]);
        }
      });
  }

  openCarUsages(timeSheetLine: ITimeSheetLine, date: Date) {
    if(timeSheetLine.cost == null)
      timeSheetLine.cost = { time_Sheet_No: timeSheetLine.time_Sheet_No, line_No: timeSheetLine.line_No };
    timeSheetLine.cost.showCarUsages = true;
    timeSheetLine.cost.carUsagesLoading = new BehaviorSubject<boolean>(true);
    return this.timeSheetService.getAdditionalCosts(timeSheetLine.time_Sheet_No, timeSheetLine.line_No, Work_Type_Use.Own_Car_Usage.toString(), date)
      .pipe(finalize(() => {
        timeSheetLine.cost.carUsagesLoading.next(false);
      }))
      .subscribe((results) => {
        if (results != null) {
          if (results[0]) {
            timeSheetLine.cost.carUsagesFormArray = this.fb.array([]);
            timeSheetLine.cost.carUsagesFormGroup = this.fb.group({ costs: timeSheetLine.cost.carUsagesFormArray });
            timeSheetLine.cost.carUsagesDataSource = new MatTableDataSource(results[0]);
            timeSheetLine.cost.carUsagesCosts = [];
            timeSheetLine.bS005_Own_Car_Usage_Amnt_LCY = 0;
            Enumerable.from(results[0]).ToArray().forEach((d: IAdditionalCostModel) => {
              var costFormGroup = this.buildCost(timeSheetLine, d, date)
              timeSheetLine.cost.carUsagesFormArray.push(costFormGroup);
              timeSheetLine.cost.carUsagesCosts.push(d);
              timeSheetLine.bS005_Own_Car_Usage_Amnt_LCY += d.amount_LCY;
            });
          }
          if (results[1])
            this.updateTotalsCosts(results[1]);
        }
      });
  }

  editAllowanceCost(timeSheetLine: ITimeSheetLine, cost: any, date: Date) {

    this.validationService.clearValidationMessage();
    timeSheetLine.date = date;
    this.loading.next(true);
    this.timeSheetService.updateLine(timeSheetLine).subscribe(r => {
      this.clearSelection();
      timeSheetLine.isDirty = false;
      timeSheetLine.enableCost = false;
      if (r.timeSheetLineSubForm != null)
        timeSheetLine.enableCost = r.timeSheetLineSubForm.enableCost;
      this.updateTotals();
      //this.showMessage(this.translateService.instant('SAVE_SUCCESS_MESSAGE'), '', false);
      const dialogRef = this.dialog.open(AdditionalCostsDialogComponent, {
        data: {
          timeSheetLine: timeSheetLine,
          cost: cost,
          workTypeUse: Work_Type_Use.Allowance,
          date: date,
          disable: this.disableAllowance
        },
        width: '800px'
      });

      dialogRef.afterClosed().subscribe(result => {
        this.openAllowances(timeSheetLine, date);
        this.validationService.clearValidationMessage();
      });
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  editExpenseCost(timeSheetLine: ITimeSheetLine, cost: any, date: Date) {
    this.validationService.clearValidationMessage();
    timeSheetLine.date = date;
    this.loading.next(true);
    this.timeSheetService.updateLine(timeSheetLine).subscribe(r => {
      this.clearSelection();
      timeSheetLine.isDirty = false;
      timeSheetLine.enableCost = false;
      if (r.timeSheetLineSubForm != null)
        timeSheetLine.enableCost = r.timeSheetLineSubForm.enableCost;
      this.updateTotals();
      const dialogRef = this.dialog.open(AdditionalCostsDialogComponent, {
        data: {
          timeSheetLine: timeSheetLine,
          cost: cost,
          workTypeUse: Work_Type_Use.Expense,
          date: date
        },
        width: '800px'
      });

      dialogRef.afterClosed().subscribe(result => {
        this.openExpenses(timeSheetLine, date);
      });
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });

    
  }

  editCarUsageCost(timeSheetLine: ITimeSheetLine, cost: any, date: Date) {

    this.validationService.clearValidationMessage();
    timeSheetLine.date = date;
    this.loading.next(true);
    this.timeSheetService.updateLine(timeSheetLine).subscribe(r => {
      this.clearSelection();
      timeSheetLine.isDirty = false;
      timeSheetLine.enableCost = false;
      if (r.timeSheetLineSubForm != null)
        timeSheetLine.enableCost = r.timeSheetLineSubForm.enableCost;
      this.updateTotals();
      const dialogRef = this.dialog.open(AdditionalCostsDialogComponent, {
        data: {
          timeSheetLine: timeSheetLine,
          cost: cost,
          workTypeUse: Work_Type_Use.Own_Car_Usage,
          date: date
        },
        width: '800px'
      });

      dialogRef.afterClosed().subscribe(result => {
        this.openCarUsages(timeSheetLine, date);
      });
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  reopenApprovedSelected() {
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    if (this.selection) {
      this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
      this.selection.selected.forEach((selected: ITimeSheetLine) => {
        if (selected) {
          let line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x =>
            (<ITimeSheetLine>x).time_Sheet_No == (<ITimeSheetLine>selected).time_Sheet_No &&
            (<ITimeSheetLine>x).line_No == (<ITimeSheetLine>selected).line_No);
          line.selected = true;
        }
      });
    }
    this.timeSheetService.reopenApprovedSelected(this.timeSheet).subscribe(r => {
      this.onAfterGetTimeSheet(r);
      this.showMessage(this.translateService.instant('REOPEN_SUCCESS_MESSAGE'), '', false);
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  clearSelection() {
    this.selection.clear();
    this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine) => d.selected = false);
  }

  setColumns() {
    this.getDates(this.timeSheet.starting_Date, this.timeSheet.ending_Date);
    this.dateArray.forEach((d: Date, i: number) => {
      this.columnRecords[i] = d;
    });
  }

  getDates(startDate, stopDate) {
    var start = moment(startDate);
    var stop = moment(stopDate);
    this.dateArray = [];
    while (start <= stop) {
      this.dateArray.push(this.offsetDatePipe.transform(start))
      start = moment(start).add(1, 'days');
    }
  }

  getQuantity(line: ITimeSheetLine) {
    let values: number[] = [];
    if (line) {
      for (var i = 0; i <= this.dateArray.length; i++) {
        values[i] = 0;
        if (line.line_No != 0) {
            var details = <ITimeSheetLineDetail>Enumerable.from(this.timeSheet.timeSheetDetailLine).Where(x =>
              (<ITimeSheetLineDetail>x).time_Sheet_No == this.timeSheet.no &&
              (<ITimeSheetLineDetail>x).time_Sheet_Line_No == line.line_No).ToArray();

          if (details != undefined && details != null) {
            Enumerable.from(<ITimeSheetLineDetail[]>details).ToArray().forEach((d: ITimeSheetLineDetail) => {
              if (d != undefined && d.date != undefined && d.date != null && this.dateArray[i] != undefined && this.dateArray[i] != '') {
                let dt = this.dateArray[i];
                let dt1 = this.offsetDatePipe.transform(d.date);
                if (dt == dt1)
                    values[i] = Number(d.quantity);
              }
            });
          }
        }
      }
    }
    return values;
  }

  getLineQuantity(line: ITimeSheetLine, i: number) {
    var values = this.getQuantity(line);
    return values[i];
  }

  getTotalDateQuantity(fieldNo: number) {
    if (this.timeSheet) {
      if (this.timeSheet.summary) {
        return this.timeSheet.summary.quantities[fieldNo - 1] + '/' + this.timeSheet.summary.capacities[fieldNo - 1];
      }
    }
  }

  getTotalQuantity() {
    if (this.timeSheet) {
      if (this.timeSheet.summary) {
        let q = Enumerable.from(this.timeSheet.summary.quantities).Sum(x => <number>x);
        let c = Enumerable.from(this.timeSheet.summary.capacities).Sum(x => <number>x);
        return q.toLocaleString() + '/' + c.toLocaleString();
      }
    }
  }

  getTotalCapacity() {
    if (this.timeSheet) {
      if (this.timeSheet.summary) {
        let c = Enumerable.from(this.timeSheet.summary.capacities).Sum(x => <number>x);
        return c.toLocaleString();
      }
    }
  }

  getHint(fieldNo: number) {
    let qty = this.timeSheet.summary.quantities[fieldNo];
    let cpy = this.timeSheet.summary.capacities[fieldNo];
    return (qty.toLocaleString() + "/" + cpy.toLocaleString());
  }

  getHintClass(fieldNo: number) {
    let qty = this.timeSheet.summary.quantities[fieldNo];
    let cpy = this.timeSheet.summary.capacities[fieldNo];
    if (qty < cpy)
      return 'text-warning';
    if (qty == cpy)
      return 'text-success';
    if (qty > cpy)
      return 'text-danger';
  }

  openComments(line: ITimeSheetLine) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: this.translateService.instant('COMMENTS_BUTTON'),
        message: line.comments,
        buttonText: {
          cancel: this.translateService.instant('CLOSE_BUTTON')
        }
      }
    });
  }

  showMessage(message: string, action: string, isError: boolean) {
    let _class = 'mat-accent';
    if (isError && isError == true)
      _class = 'mat-warn'
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['mat-toolbar', _class],
      direction: "ltr",
      horizontalPosition: "center",
    });
  }

  setDirty(line: ITimeSheetLine, dirty: boolean) {
    line.isDirty = dirty;
    this.isDirtyLines();
  }

  isDirtyLines() {
    this.isDirty = Enumerable.from(this.timeSheet.timeSheetLineSubForm).Any(x => (<ITimeSheetLine>x).isDirty === true);
    return this.isDirty;
  }

  buildCost(timeSheetLine: ITimeSheetLine, addCost: IAdditionalCostModel, date: Date): FormGroup {
    const row = this.fb.group({
      'key': [addCost && addCost.key ? addCost.key : null, []],
      'posting_Date': [addCost && addCost.posting_Date ? addCost.posting_Date : date, [Validators.required]],
      'resource_No': [addCost && addCost.resource_No ? addCost.resource_No : null, [Validators.required]],
      'time_Sheet_No': [addCost && addCost.time_Sheet_No ? addCost.time_Sheet_No : timeSheetLine.time_Sheet_No, [Validators.required]],
      'time_Sheet_Line_No': [addCost && addCost.time_Sheet_Line_No ? addCost.time_Sheet_Line_No : timeSheetLine.line_No, [Validators.required]],
      'work_Type_Code': [addCost && addCost.work_Type_Code ? addCost.work_Type_Code : null, [Validators.required]],
      'description': [addCost && addCost.description ? addCost.description : null, [Validators.required, Validators.maxLength(100)]],
      'unit_of_Measure_Code': [addCost && addCost.unit_of_Measure_Code ? addCost.unit_of_Measure_Code : null, [Validators.required]],
      'quantity': [addCost && addCost.quantity ? addCost.quantity : 1, [Validators.required]],
      'amount_LCY': [addCost && addCost.amount_LCY ? addCost.amount_LCY : null, [Validators.required]],
      'entry_No': [addCost && addCost.entry_No ? addCost.entry_No : null, []],
      'job_No': [addCost && addCost.job_No ? addCost.job_No : timeSheetLine.job_No, [Validators.required]],
      'job_Task_No': [addCost && addCost.job_Task_No ? addCost.job_Task_No : timeSheetLine.job_Task_No, [Validators.required]],
      'job_Ledger_Entry_No': [addCost && addCost.job_Ledger_Entry_No ? addCost.job_Ledger_Entry_No : null, []],
      'posted': [addCost && addCost.posted ? addCost.posted : null, []],
      'work_Type_Use': [addCost && addCost.work_Type_Use ? addCost.work_Type_Use : Work_Type_Use.Allowance, [Validators.required]],
    });
    return(row);
  }
}
