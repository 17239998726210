import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Http, Headers, Response, RequestOptionsArgs } from '@angular/http';
import 'rxjs/add/operator/map'
import { IUser, IRenewalToken, IChangePassword } from "../models/IUser";
import { IUserWithToken } from "../models/IUserWithToken";
import { APP_CONFIG, AppConfig } from '../shared/app-config.module';
import 'rxjs/add/operator/catch'; // don't forget this, or you'll get a runtime error
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpService } from '../shared/http.service';
import { ICompany, ISetup } from '../models/ICompany';

@Injectable()
export class AuthService {
  token: string = null;
  user: IUser = <IUser>null;

  userLoggedIn: EventEmitter<IUser> = new EventEmitter();
  userLoggedOut: EventEmitter<any> = new EventEmitter();

  constructor(private http: Http,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  login(username: string, password: string, language: string, serverInstance: string, companyName: string): Observable<IUserWithToken> {
    var options = this.getHttpOptions();
    return this.http.post(this.config.apiEndpoint + "/authentication/authenticate", { Username: username, Password: password, Language: language, serverInstance: serverInstance, companyName: companyName}, options)
      .map((response: Response) => {
        const responseJson = <IUserWithToken><unknown>response.json();
        this.loginInternal(responseJson);
        return responseJson;
      }).catch(r => throwError(r));
  }

  register(user) {
    return this.http.post(this.config.apiEndpoint + "/authentication/register", user).map((response: Response) => {
      const responseJson = <IUser><unknown>response.json();
      localStorage.setItem(this.config.storageApplicationConfiguration, JSON.stringify(responseJson.config));
      return response;
    }).catch(r => throwError(r));
  }

  changePassword(model: IChangePassword) {
    return this.http.post(this.config.apiEndpoint + "/authentication/changepassword", model).map((response: Response) => {
      const responseJson = <IUser><unknown>response.json();
      localStorage.setItem(this.config.storageApplicationConfiguration, JSON.stringify(responseJson.config));
      return response;
    }).catch(r => throwError(r));
  }

  renewalToken(model: IRenewalToken) {
    return this.http.post(this.config.apiEndpoint + "/authentication/renewaltoken", model).map((response: Response) => {
      const responseJson = <IUser><unknown>response.json();
      localStorage.setItem(this.config.storageApplicationConfiguration, JSON.stringify(responseJson.config));
      return response;
    }).catch(r => throwError(r));
  }

  forgotPassword(user: IChangePassword) {
    return this.http.post(this.config.apiEndpoint + "/authentication/forgotpassword", user).map((response: Response) => {
      const responseJson = <IUser><unknown>response.json();
      localStorage.setItem(this.config.storageApplicationConfiguration, JSON.stringify(responseJson.config));
      return response;
    }).catch(r => throwError(r));
  }

  tryGetInfoFromStore(): boolean {
    var data = sessionStorage.getItem(this.config.storageKeyUser);

    if (data === null) {
      return false;
    }

    this.loginInternal(JSON.parse(data));
    return true;
  }

  private loginInternal(data: IUserWithToken) {
    this.token = data.token;
    this.user = data.user;
    var d = JSON.stringify(data);
    sessionStorage.setItem(this.config.storageKeyUser, d);
    localStorage.setItem(this.config.storageApplicationConfiguration, JSON.stringify(data.config));

    //this.userLoggedIn.emit(this.user);
  }

  logout() {
    // remove user from local storage to log user out
    this.token = null;
    this.user = <IUser>null;

    sessionStorage.removeItem(this.config.storageKeyUser);
    sessionStorage.removeItem(this.config.storageKeyAvatar);
    sessionStorage.removeItem(this.config.storageKeyLanguage);
    
    this.userLoggedOut.emit(null);
  }

  isLoggedIn() {
    return this.token != null;
  }

  getCurrentUser(): IUser {
    var data = JSON.parse(sessionStorage.getItem(this.config.storageKeyUser));
    var config = JSON.parse(localStorage.getItem(this.config.storageApplicationConfiguration));
    var user = (<any>data).user;
    (<IUser>user).config = config;
    return user;
  }

  loadCompanies(serverInstance: string): Observable<ICompany[]> {
    var obj = {
      name: serverInstance
    };
    return this.http.post(this.config.apiEndpoint + '/discovery/companies', obj)
      .map((r) => {
        let response = r.json();
        let result = <ICompany[]>(response);
        return result;
      })
      .catch((r) => { return throwError(r); });
  }

  getSetup(): Observable<ISetup> {
    return this.http.get(this.config.apiEndpoint + '/discovery/setup')
      .map((r) => {
        let response = r.json();
        let result = <ISetup>(response);
        sessionStorage.setItem(this.config.storageSetupKey, JSON.stringify(result));
        return result;
      })
      .catch((r) => { return throwError(r); });
  }
    

  public getHttpOptions(): RequestOptionsArgs {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + this.token);
    return {
      headers
    };
  }

  private handleResponse(res) {
    //debugger;
    console.log(res);
    // If request fails, throw an Error that will be caught
    if (res.status == 401) {
      this.logout();
      this.router.navigate(["/login"]);
      return Observable.throw(res);;
    }
  }

}
