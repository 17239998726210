import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "../../security/auth.service";
import { AlertService } from "../../shared/alert/alert.service";
import { MetadataService } from '../../shared/metadata/metadata.service';
import { APP_CONFIG, AppConfig } from '../../shared/app-config.module';
import { ValidationService } from '../../shared/validation/validation.service';
import { LanguageService } from '../../shared/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { IApplicationConfiguration } from '../../models/IApplicatinConfiguration';
import { ICompany } from '../../models/ICompany';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.style.css']
})

export class RegisterComponent implements OnInit {
  model: any = {};
  submitted = false;
  registerForm: FormGroup;
  metadata: any = {};
  attributes: any = [];
  language: string;
  loading = new BehaviorSubject<boolean>(false);
  configuration: IApplicationConfiguration;
  filteredCompanies: ICompany[];
  regexUrl = ".*"  //Needed (ngPattern surrounds with ^ and $)
    + "(?=^.{6,}$)"       //At least 6 Characters
    + "(?=[^\\d]*\\d)"    //At least one digit
    + "(?=[^\\W]*\\W)"    //At least one special character
    + "(?=[^A-Z]*[A-Z])"  //At least one uppercase character
    + "(?=[^a-z]*[a-z])"  //At least one lowercase character
    + ".*";    //Needed (ngPattern surrounds with ^ and $)

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private validationService: ValidationService,
    private metadataService: MetadataService,
    private formBuilder: FormBuilder,
    public languageService: LanguageService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private config: AppConfig) {

    var configuration = localStorage.getItem(config.storageApplicationConfiguration);
    if (configuration != null && configuration != "")
      this.configuration = JSON.parse(configuration);
    else
      this.configuration = <IApplicationConfiguration>{};

  }

  ngOnInit() {
    this.language = this.languageService.getLanguage();
    this.authService.logout();
    this.getMetadata();

    this.registerForm = this.formBuilder.group({
      id: [''],
      resource_No: ['', Validators.required],
      username: ['', Validators.required],
      full_Name: ['', Validators.required],
      eMail: ['', [Validators.required, Validators.email]],
      language: [this.language, Validators.required],
      serverInstance: [this.configuration.serverInstance, [Validators.required, Validators.pattern(this.regexUrl)]],
      companyName: [this.configuration.companyName, Validators.required],
    });
  }

  onSubmit(model) {
    this.submitted = true;
    this.registerForm.controls['id'].updateValueAndValidity();

    if (this.registerForm.invalid) {
      return;
    }
    this.loading.next(true);
    this.authService.register(model.value)
      .subscribe(() => {
        this.loading.next(false);
        this.alertService.success(this.translateService.instant('REGISTRATION_SUCCESFULLY'));
        //this.router.navigate(['/login']);
      }, error => {
        let obj = JSON.parse(error._body);
        this.validationService.mapValidationServerMessage(obj, this.registerForm);
      }).add(() => {
        this.loading.next(false);
      });
  }

  getMetadata() {

  };

  onChangeServerInstance() {
    this.registerForm.controls.companyName.setValue('');
    this.filteredCompanies = [];
  }


  onFocusCompanyName() {
    this.lookupCompanyName();
  }

  lookupCompanyName() {
    this.alertService.clear();
    this.loading.next(true);
    var serverInstance = this.registerForm.controls.serverInstance.value;
    return this.authService.loadCompanies(serverInstance)
      .pipe(
        finalize(() => {
          this.loading.next(false);
        }))
      .subscribe((results) => {
        this.filteredCompanies = results;
      }, (error) => {
        var err = error.json();
        this.alertService.error(err.message);
      });
  }
}
