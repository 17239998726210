import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  Input,
  HostBinding,
  OnInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems, Menu } from '../../../shared/menu-items/menu-items';
import { AuthService } from "../../../security/auth.service";
import { IUser } from '../../../models/IUser';
import { UserService } from '../../../security/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, state, transition, animate, style } from '@angular/animations';
import * as Enumerable from "linq-es2015";
import { BroadcastService } from '../../../shared/broadcast.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})

export class AppSidebarComponent implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: Menu;
  avatar: any = "";
  private _mobileQueryListener: () => void;
  navItems: Menu[];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    userService: UserService,
    private broadcastService: BroadcastService
  ) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    userService.getAvatar(authService.user.username).subscribe((result) => {
      if (result != undefined && result != null && result != "")
        this.avatar = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + result);
      else
        this.avatar = null;
    });
    this.broadcastService.subscribe("AVATAR-CHANGE", (newAvatar) => { this.avatar = newAvatar });
  }

  ngOnInit(): void {
    this.navItems = this.menuItems.getMenuitem();
    this.route.queryParams.subscribe(params => {
      if (params.view != undefined && params.view != null) {
        Enumerable.from(this.navItems).ToArray().forEach((item: Menu) => {
          this.isActive(item, 'view', params.view);
          if (item.children && item.children.length > 0) {
            Enumerable.from(item.children).ToArray().forEach((children) => {
              this.isActive(children, 'view', params.view);
            });
          }
        });
      }
    });
  }

  isActive(item: Menu, key: string, value: string) {
    if (item.params) {
      if (item.params[key]) {
        if (item.params[key] == value) {
          item.active = true;
          return;
        }
      }
    }
    item.active = false;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getCurrentUser() {
    return this.authService.getCurrentUser();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  goToProfile() {
    this.router.navigateByUrl('/profile');
  };

}
