import { Enumerable } from 'linq-es2015';
import { IJobTaskReference, IJobReference, IAbsenceCode, IWorkTypeCode } from './IJobReference';
import { FormArray, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

export interface ITimeSheet {
  key?: string;
  no: string;
  resourceNo: string;
  endDate?: Date;
  endDateSpecified?: boolean;
  startDate?: Date;
  startDateSpecified?: boolean;
  timeSheetNo?: string;
  userID?: string;
  comment?: boolean;
  commentSpecified?: boolean;
}

export interface ITimeSheetCardModel {

  key?: string;
  no?: string;
  starting_Date?: Date;
  starting_DateSpecified?: boolean;
  ending_Date?: Date;
  ending_DateSpecified?: boolean;
  resource_No?: string;
  comment?: boolean;
  commentSpecified?: boolean;
  timeSheetLineSubForm?: ITimeSheetLine[];
  timeSheetDetailLine?: ITimeSheetLineDetail[];
  summary?: IActualScheduledSummaryModel;
  statusSummary?: IStatusSummaryModel;
  timeSheetLineGroups?: ITimeSheetGroup[];
  timeSheetArrayGroups?: FormArray;
}

export interface ITimeSheetGroup
{
  key?: string;
  timeSheetLines?: ITimeSheetLine[];
}

export interface ITimeSheetLine {
  key?: string;
  time_Sheet_No?: string | null;
  line_No?: number;
  line_NoSpecified?: boolean;
  type?: TypeLine | null;
  typeSpecified?: boolean;
  job_No?: string | null;
  jobDescription?: string | null;
  job_Task_No?: string | null;
  description?: string;
  cause_of_Absence_Code?: string;
  chargeable?: boolean;
  chargeableSpecified?: boolean;
  work_Type_Code?: string;
  service_Order_No?: string;
  assembly_Order_No?: string;
  field1?: number | null;
  field1Specified?: boolean;
  field2?: number | null;
  field2Specified?: boolean;
  field3?: number | null;
  field3Specified?: boolean;
  field4?: number | null;
  field4Specified?: boolean;
  field5?: number | null;
  field5Specified?: boolean;
  field6?: number;
  field6Specified?: boolean;
  field7?: number;
  field7Specified?: boolean;
  chargeableQty1?: number | null;
  chargeableQty1Specified?: boolean;
  chargeableQty2?: number | null;
  chargeableQty2Specified?: boolean;
  chargeableQty3?: number | null;
  chargeableQty3Specified?: boolean;
  chargeableQty4?: number | null;
  chargeableQty4Specified?: boolean;
  chargeableQty5?: number | null;
  chargeableQty5Specified?: boolean;
  chargeableQty6?: number;
  chargeableQty6Specified?: boolean;
  chargeableQty7?: number;
  chargeableQty7Specified?: boolean;
  extDoc1?: string;
  extDoc2?: string;
  extDoc3?: string;
  extDoc4?: string;
  extDoc5?: string;
  extDoc6?: string;
  extDoc7?: string;
  total_Quantity?: number;
  totalQuantityOnCapacity?: string;
  total_QuantitySpecified?: boolean;
  status?: StatusLine;
  statusSpecified?: boolean;
  resource_No?: string;
  extDocMandatory?: boolean;
  selected?: boolean;
  filteredJobs?: IJobReference[];
  filteredJobTasks?: IJobTaskReference[];
  filteredAbsencesCodes?: IAbsenceCode[];
  filteredWorkTypesCodes?: IWorkTypeCode[];
  editable?: boolean;
  date?: Date;
  isDirty?: boolean;
  comments?: string;
  bS005_Allowance_Amount_LCY ?: number | null;
  bS005_Expense_Amount_LCY ?: number | null;
  bS005_Own_Car_Usage_Amnt_LCY?: number | null;
  cost?: Cost;
  enableCost?: boolean | null;
  bS005_Not_Worked_Add_Cost?: boolean | null;
}

export interface Cost {
  time_Sheet_No?: string | null;
  line_No?: number;
  showAllowances?: boolean | null;
  showExpenses?: boolean | null;
  showCarUsages?: boolean | null;
  allowancesFormArray?: FormArray;
  allowancesFormGroup?: FormGroup;
  allowancesDataSource?: MatTableDataSource<IAdditionalCostModel>;
  allowancesLoading?: BehaviorSubject<boolean>;
  expensesFormArray?: FormArray;
  expensesFormGroup?: FormGroup;
  expensesDataSource?: MatTableDataSource<IAdditionalCostModel>;
  expensesLoading?: BehaviorSubject<boolean>;
  carUsagesFormArray?: FormArray;
  carUsagesFormGroup?: FormGroup;
  carUsagesDataSource?: MatTableDataSource<IAdditionalCostModel>;
  carUsagesLoading?: BehaviorSubject<boolean>;
  allowancesCosts?: IAdditionalCostModel[];
  expensesCosts?: IAdditionalCostModel[];
  carUsagesCosts?: IAdditionalCostModel[];
}

export interface UpdateTimeSheetLine {
  timeSheetNo: string;
  timeSheetLineSubForm: ITimeSheetLine;
  timeSheetDetailLine: ITimeSheetLineDetail[];
  summary?: IActualScheduledSummaryModel;
  statusSummary?: IStatusSummaryModel;
}

export interface ITimeSheetLineDetail {
  key?: string;
  time_Sheet_No?: string;
  time_Sheet_Line_No?: number;
  time_Sheet_Line_NoSpecified?: boolean;
  job_No?: string | null;
  job_Task_No?: string | null;
  type?: TypeLine;
  typeSpecified?: boolean;
  resource_No?: string;
  date?: Date;
  dateSpecified?: boolean;
  quantity?: number;
  quantitySpecified?: boolean;
  status?: StatusLine;
  statusSpecified?: boolean;
  posted?: boolean;
  postedSpecified?: boolean;
  bS005_Chargeable?: boolean;
  bS005_External_Document_No?: string;
  bS005_Chargeable_Quantity?: number;
  bS005_Allowance_Amount_LCY?: number | null;
  bS005_Expense_Amount_LCY?: number | null;
  bS005_Own_Car_Usage_Amnt_LCY?: number | null;
}

export interface IActualScheduledSummaryModel {
  timeSheetNo: string;
  dateArray: Date[];
  dateDescription: string[];
  dateQuantity: string[];
  totalPresenceQty: number;
  totalCapacity: number;
  absenceQty: number;
  quantities: number[];
  capacities: number[];
  allowancesAmounts: number[];
  expensesAmounts: number[];
  ownCarUsagesAmounts: number[];
  totalAllowanceAmount?: number | null;
  totalExpenseAmount?: number | null;
  totalOwnCarUsageAmount?: number | null;
}

export interface IStatusSummaryModel {
  timeSheetNo: string;
  openQty: number;
  submittedQty: number;
  rejectedQty: number;
  approvedQty: number;
  postedQty: number;
  totalQuantity: number;
}

export interface IMyActivity {  
  openTimeSheets: number;
  submittedTimeSheets: number;
  rejectedTimeSheets: number;
  approvedTimeSheets: number;
  timeSheetsToApprove: number;
  allTimeSheets: number;
  inProgressTimeSheets: number;
}

export interface IAdditionalCostModel {
  key?: string;
  posting_Date?: string;
  posting_DateSpecified?: boolean;
  resource_No?: string
  time_Sheet_No?: string;
  time_Sheet_Line_No?: number;
  time_Sheet_Line_NoSpecified?: boolean;
  work_Type_Code?: string;
  description?: string;
  unit_of_Measure_Code?: string;
  quantity?: number;
  unit_Amount_LCY?: number;
  unit_Amount_LCYSpecified?: boolean;
  amount_LCY?: number;
  amount_LCYSpecified?: boolean;
  entry_No?: number;
  entry_NoSpecified?: boolean;
  job_No?: string;
  job_Task_No?: string;
  job_Ledger_Entry_No?: number;
  job_Ledger_Entry_NoSpecified?: boolean
  posted?: boolean;
  postedSpecified?: boolean;
  work_Type_Use?: Work_Type_Use;
  work_Type_UseSpecified?: boolean;
  fileAsBase64?: string;
  picture_Name?: string;
  Namepdf?: string;
  pdfAsBase64?: string;
}

export interface IUploadFile {
  mimeType?: string;
  content ?: string;
  name?: string;
  sourceNo?: string;
  contentByteArray?: [];
}

export enum Work_Type_Use {
  Work_Time = 0,
  Extra_Work_Time = 1,
  Smart_Working = 2,
  Expense = 10,
  Own_Car_Usage = 11,
  Allowance = 20,
}

export enum TypeLine {
  _blank_,
  Resource,
  Job,
  Service,
  Absence,
  Assembly_Order,
  Allowance = 100
}

export enum StatusLine {
  Open,
  Submitted,
  Rejected,
  Approved,
}

export enum ViewActionContex {
  MemberAction = 0,
  ManagerActions = 1,
  ManagerVerifierActions = 2
}


