import { Injectable, Inject } from '@angular/core';
import { Http } from '@angular/http';
import { IUser } from "../models/IUser";
import { APP_CONFIG, AppConfig } from '../shared/app-config.module';
import { DomSanitizer } from '@angular/platform-browser';
import 'rxjs/add/operator/map';
import { throwError, Observable, of } from 'rxjs';
import { HttpService } from "../shared/http.service";
import { IProfile } from '../models/IProfile';

@Injectable()
export class UserService {
  constructor(
    private httpService: HttpService,
    private http: Http,
    private _sanitizer: DomSanitizer,
    @Inject(APP_CONFIG) private config: AppConfig) {

  }

  getAvatar(username: string): Observable<string> {
    var avatar = sessionStorage.getItem(this.config.storageKeyAvatar);

    if (avatar != undefined && avatar != null)
      return of(avatar);

    return this.httpService.post(this.config.apiEndpoint + '/user/avatar', { username })
      .map((r) => {
        let result = <string><unknown>(r);
        sessionStorage.setItem(this.config.storageKeyAvatar, result);
        return result;
      }).catch(r => this.handleResponse(r));
  }

  getProfile(): Observable<IProfile> {
    return this.httpService.get(this.config.apiEndpoint + '/user/profile')
      .map((r) => {
        let result = <IProfile><unknown>(r);
        return result;
      }).catch((r) => {
        return this.handleResponse(r);
      });
  }

  clearAvatar(): Observable<boolean> {
    return this.httpService.get(this.config.apiEndpoint + '/user/clearavatar')
      .map((r) => {
        var isClear = <boolean><unknown>(r);
        if (isClear === true)
          sessionStorage.removeItem(this.config.storageKeyAvatar);
        return <boolean><unknown>(r);
      }).catch((r) => {
        return this.handleResponse(r);
      });
  }

  updateAvatar(profile: any) {
    return this.httpService.post(this.config.apiEndpoint + '/user/updateprofile', profile)
      .map((r) => {
        let result = <IProfile><unknown>r;
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  private handleResponse(res) {
    console.log(res);
    return throwError(res);
  }
}

