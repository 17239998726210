import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch'; // don't forget this, or you'll get a runtime error
import { Http } from '@angular/http';
import { APP_CONFIG, AppConfig } from '../../shared/app-config.module';
import { ITimeSheet, ITimeSheetCardModel, ITimeSheetLine, IMyActivity, UpdateTimeSheetLine, IAdditionalCostModel, IActualScheduledSummaryModel, IUploadFile, Cost } from '../../models/IMyTimeSheet';
import { IPaginator } from '../../models/IPaginator';
import * as Enumerable from "linq-es2015";
import { IJobReference, IJobTaskReference, IAbsenceCode, IWorkTypeCode } from '../../models/IJobReference';
import { throwError } from 'rxjs';
import { LanguageService } from '../../shared/language/language.service';
import { HttpService } from '../../shared/http.service';
import * as moment from 'moment';

@Injectable()
export class TimeSheetService {
  language: string;

  constructor(
    private httpService: HttpService,
    public languageService: LanguageService,
    @Inject(APP_CONFIG) private config: AppConfig) {
    this.language = this.languageService.getLanguage();
  }

  getTimeSheet(timeSheetNo: string): Observable<ITimeSheetCardModel> {
    return this.httpService.post(this.config.apiEndpoint + '/timesheet/get', { no: timeSheetNo })
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        return result;
      })
      .catch((r) => {
        return this.handleResponse(r);
      });
  }

  getNextRecord(no: string, resourceNo: string): Observable<ITimeSheetCardModel> {
    return this.httpService.get(this.config.apiEndpoint + '/timesheet/next/' + no + '/' + resourceNo)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        return result;
      })
      .catch((r) => {
        return this.handleResponse(r);
      });
  }

  getPrevRecord(no: string, resourceNo: string): Observable<ITimeSheetCardModel> {
    return this.httpService.get(this.config.apiEndpoint + '/timesheet/prev/' + no + '/' + resourceNo)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        return result;
      })
      .catch((r) => {
        return this.handleResponse(r);
      });
  }

  getMyActivities(): Observable<IMyActivity> {
    return this.httpService.get(this.config.apiEndpoint + '/timesheet/myactivities')
      .map((r) => {
        let result = <IMyActivity><unknown>r;
        return result;
      })
      .catch((r) => {
        return this.handleResponse(r);
      });
  }

  findTimeSheets(resourceNo: string, sortOrder = 'asc', selectedView: string, pageNumber = 0, previousPageNumber = 0, pageSize = 3, bookmarkKey = ''): Observable<IPaginator<ITimeSheet>> {
    return this.httpService.get(this.config.apiEndpoint + '/timesheet/find/' + resourceNo + '/' + sortOrder + '/' + selectedView + '/' + pageNumber + '/' + previousPageNumber + '/' + pageSize + '?bookmarkKey=' + bookmarkKey)
      .map((r) => {
        let response = <any>r;
        let result = <IPaginator<ITimeSheet>>(response.item1);
        result.totalCount = response.item2;
        var first = Enumerable.from(response.item1).FirstOrDefault();
        if (first != null)
          result.bookmarkFirstKey = (<any>first).key;
        var last = Enumerable.from(response.item1).LastOrDefault();
        if (last != null)
          result.bookmarkLastKey = (<any>last).key;
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  findJobs(sheetNo: string, jobNo: string, resourceNo: string, sortOrder = 'asc', pageNumber = 0, pageSize = 30, bookmarkKey = ''): Observable<IJobReference[]> {
    let url = '/metadata/findjobs/' + sheetNo + '/' + resourceNo;
    url += '?no=' + jobNo;
    return this.httpService.get(this.config.apiEndpoint + url)
      .map((r) => {
        let response = <any>r;
        let result = <IJobReference[]>(response.item1);
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  findAbsencesCodes(sheetNo: string, absenceCode: string, sortOrder = 'asc', pageNumber = 0, pageSize = 30, bookmarkKey = ''): Observable<IAbsenceCode[]> {
    let url = '/metadata/findabsencescodes/' + sheetNo;
    url += '?code=' + absenceCode;
    return this.httpService.get(this.config.apiEndpoint + url)
      .map((r) => {
        let response = <any>r;
        let result = <IAbsenceCode[]>(response.item1);
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  findWorkTypeCodes(code: string, workTypeUse: number = null, timeSheetNo: string = null, timeSheetLineNo: number = null): Observable<IWorkTypeCode[]> {
    let url = '/metadata/findworktypescodes';
    url += '?code=' + code;
    url += '&workTypeUse=' + workTypeUse;
    url += '&timeSheetNo=' + timeSheetNo;
    url += '&timeSheetLineNo=' + timeSheetLineNo;
    return this.httpService.get(this.config.apiEndpoint + url)
      .map((r) => {
        let response = <any>r;
        let result = <IWorkTypeCode[]>(response.item1);
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  findJobTasks(sheetNo: string, jobNo: string, jobTaskNo: string, resourceNo: string, sortOrder = 'asc', pageNumber = 0, pageSize = 30, bookmarkKey = ''): Observable<IJobTaskReference[]> {
    let url = '/metadata/findjobtasks/' + sheetNo + '/' + resourceNo;
    url += '?jobNo=' + jobNo;
    url += '&jobTaskNo=' + jobTaskNo;
    return this.httpService.get(this.config.apiEndpoint + url)
      .map((r) => {
        let response = <any>r;
        let result = <IJobTaskReference[]>(response.item1);
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  update(timeSheet: ITimeSheetCardModel): Observable<ITimeSheetCardModel> {
    let toPost = (<any>Object).assign({}, timeSheet);
    const costs = [];
    toPost.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      costs.push(line.cost);
      line.cost = null;
    });
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/update/' + timeSheet.no, timeSheet)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        result.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
          var c = Enumerable.from(costs).FirstOrDefault(x => x != null && (<Cost>x).time_Sheet_No != null && (<Cost>x).time_Sheet_No == line.time_Sheet_No && (<Cost>x).line_No == line.line_No);
          if (c != null)
            line.cost = c;
        });
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  updateLine(timeSheetLine: ITimeSheetLine): Observable<UpdateTimeSheetLine> {
    let toPost = (<any>Object).assign({}, timeSheetLine);
    const cost = timeSheetLine.cost;
    toPost.cost = null;
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/updateline/' + timeSheetLine.time_Sheet_No, toPost)
      .map((r) => {
        let result = <UpdateTimeSheetLine><unknown>r;
        result.timeSheetLineSubForm.cost = cost;
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  submitSelectedLine(timeSheetLine: ITimeSheetLine): Observable<UpdateTimeSheetLine> {
    let toPost = (<any>Object).assign({}, timeSheetLine);
    const cost = timeSheetLine.cost;
    toPost.cost = null;
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/submitline/' + timeSheetLine.time_Sheet_No, toPost)
      .map((r) => {
        let result = <UpdateTimeSheetLine><unknown>r;
        result.timeSheetLineSubForm.cost = cost;
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  submitSelected(timeSheet: ITimeSheetCardModel): Observable<ITimeSheetCardModel> {
    let toPost = (<any>Object).assign({}, timeSheet);
    const costs = [];
    toPost.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      costs.push(line.cost);
      line.cost = null;
    });
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/submit/' + timeSheet.no, toPost)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        result.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
          if (costs != undefined && costs != null) {
            var c = Enumerable.from(costs).FirstOrDefault(x => (<Cost>x) != undefined && (<Cost>x) != null && (<Cost>x).time_Sheet_No != null && (<Cost>x).time_Sheet_No == line.time_Sheet_No && (<Cost>x).line_No == line.line_No);
            if (c != null)
                line.cost = c;
          }
        });
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  reopenSelected(timeSheet: ITimeSheetCardModel): Observable<ITimeSheetCardModel> {
    let toPost = (<any>Object).assign({}, timeSheet);
    const costs = [];
    toPost.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      costs.push(line.cost);
      line.cost = null;
    });
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/reopen/' + timeSheet.no, toPost)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        result.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
          var c = Enumerable.from(costs).FirstOrDefault(x => (<Cost>x).time_Sheet_No != null && (<Cost>x).time_Sheet_No == line.time_Sheet_No && (<Cost>x).line_No == line.line_No);
          if (c != null)
            line.cost = c;
        });
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  verifySelected(timeSheet: ITimeSheetCardModel): Observable<ITimeSheetCardModel> {
    let toPost = (<any>Object).assign({}, timeSheet);
    const costs = [];
    toPost.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      costs.push(line.cost);
      line.cost = null;
    });
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/verify/' + timeSheet.no, toPost)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        result.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
          var c = Enumerable.from(costs).FirstOrDefault(x => (<Cost>x).time_Sheet_No != null && (<Cost>x).time_Sheet_No == line.time_Sheet_No && (<Cost>x).line_No == line.line_No);
          if (c != null)
            line.cost = c;
        });
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  approveSelected(timeSheet: ITimeSheetCardModel): Observable<ITimeSheetCardModel> {
    let toPost = (<any>Object).assign({}, timeSheet);
    const costs = [];
    toPost.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      costs.push(line.cost);
      line.cost = null;
    });
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/approve/' + timeSheet.no, toPost)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        result.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
          var c = Enumerable.from(costs).FirstOrDefault(x => (<Cost>x).time_Sheet_No != null && (<Cost>x).time_Sheet_No == line.time_Sheet_No && (<Cost>x).line_No == line.line_No);
          if (c != null)
            line.cost = c;
        });
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  rejectSelected(timeSheet: ITimeSheetCardModel): Observable<ITimeSheetCardModel> {
    let toPost = (<any>Object).assign({}, timeSheet);
    const costs = [];
    toPost.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      costs.push(line.cost);
      line.cost = null;
    });
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/reject/' + timeSheet.no, toPost)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        result.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
          var c = Enumerable.from(costs).FirstOrDefault(x => (<Cost>x).time_Sheet_No != null && (<Cost>x).time_Sheet_No == line.time_Sheet_No && (<Cost>x).line_No == line.line_No);
          if (c != null)
            line.cost = c;
        });
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  reopenApprovedSelected(timeSheet: ITimeSheetCardModel): Observable<ITimeSheetCardModel> {
    let toPost = (<any>Object).assign({}, timeSheet);
    const costs = [];
    toPost.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      costs.push(line.cost);
      line.cost = null;
    });
    return this.httpService.put(this.config.apiEndpoint + '/timesheet/reopenapproved/' + timeSheet.no, toPost)
      .map((r) => {
        let result = <ITimeSheetCardModel><unknown>r;
        result.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
          var c = Enumerable.from(costs).FirstOrDefault(x => (<Cost>x).time_Sheet_No != null && (<Cost>x).time_Sheet_No == line.time_Sheet_No && (<Cost>x).line_No == line.line_No);
          if (c != null)
            line.cost = c;
        });
        return result;
      })
      .catch(r => this.handleResponse(r));
  }

  delete(timeSheetNo: string, timeSheet: ITimeSheetCardModel): Observable<[]> {
    timeSheet.timeSheetLineSubForm.forEach((line: ITimeSheetLine) => {
      line.cost = null;
    });
    return this.httpService.post(this.config.apiEndpoint + '/timesheet/deletelines/' + timeSheetNo, timeSheet)
      .map((r) => {
        let result = <[]><unknown>r;
        return result;
      }).catch((r) => {
        return this.handleResponse(r);
      });
  }

  deleteLine(timeSheetNo: string, lineNo: number): Observable<boolean> {
    return this.httpService.delete(this.config.apiEndpoint + '/timesheet/deleteline/' + timeSheetNo + '/' + lineNo)
      .map((r) => {
        let result = <boolean><unknown>r;
        return result;
      }).catch((r) => {
        return this.handleResponse(r);
      });
  }

  addLine(timeSheetNo: string, type: number, date?: string): Observable<UpdateTimeSheetLine> {
    var url = this.config.apiEndpoint + '/timesheet/addline/' + timeSheetNo + '/' + type;
    if (date != undefined && date != null)
      url += '/' + date;
    return this.httpService.get(url)
      .map((r) => {
        let result = <UpdateTimeSheetLine><unknown>r;
        return result;
      }).catch((r) => {
        return this.handleResponse(r);
      });
  }

  copyLinesFromPrevTimeSheet(timeSheetNo: string): Observable<boolean> {
    var url = this.config.apiEndpoint + '/timesheet/copyfromprevtimesheet/' + timeSheetNo;
    return this.httpService.get(url)
      .map((r) => {
        let result = <boolean><unknown>r;
        return result;
      }).catch((r) => {
        return this.handleResponse(r);
      });
  }

  getAdditionalCosts(timeSheetNo: string, lineNo: number, workTypeUseFilter: string, date: Date): Observable<[IAdditionalCostModel[], IActualScheduledSummaryModel]> {
    var url = this.config.apiEndpoint + '/timesheet/additionalcosts/' + timeSheetNo + '/' + lineNo + '/' + workTypeUseFilter + '/' + moment(date).format("YYYY-MM-DD");
    return this.httpService.get(url)
      .map((r) => {
        let result = <[IAdditionalCostModel[], IActualScheduledSummaryModel]><unknown>r;
        return <[IAdditionalCostModel[], IActualScheduledSummaryModel]><unknown>[(<any>result).item1, (<any>result).item2];
      }).catch((r) => {
        return this.handleResponse(r);
      });
  }

  updateCost(cost: IAdditionalCostModel): Observable<IAdditionalCostModel> {
    if (cost.entry_No == null) {
      return this.httpService.post(this.config.apiEndpoint + '/timesheet/addcost', cost)
        .map((r) => {
          let result = <IAdditionalCostModel><unknown>r;
          return result;
        }).catch(r => this.handleResponse(r));
    } else {
      return this.httpService.put(this.config.apiEndpoint + '/timesheet/updatecost/' + cost.entry_No, cost)
        .map((r) => {
          let result = <IAdditionalCostModel><unknown>r;
          return result;
      }).catch(r => this.handleResponse(r));
    }
  }

  deleteCost(entryNo: number): Observable<boolean> {
    return this.httpService.delete(this.config.apiEndpoint + '/timesheet/deletecost/' + entryNo)
      .map((r) => {
        let result = (<any>r)._body;
        return result;
      }).catch(r => this.handleResponse(r));
  }

  getPictureCost(entryNo: number): Observable<IUploadFile> {
    return this.httpService.get(this.config.apiEndpoint + '/timesheet/picturecost/' + entryNo)
      .map((r) => {
        let result = <IUploadFile><unknown>r;
        return result;
      }).catch(r => this.handleResponse(r));
  }

  deletePictureCost(entryNo: number): Observable<boolean> {
    return this.httpService.delete(this.config.apiEndpoint + '/timesheet/deletepicturecost/' + entryNo)
      .map((r) => {
        let result = <boolean><unknown>r;
        return result;
      }).catch(r => this.handleResponse(r));
  }

  getExcelWorkShett(year: number, month: number): Observable<IUploadFile> {
    return this.httpService.get(this.config.apiEndpoint + '/timesheet/exportworksheet/' + year + '/' + month)
      .map((r) => {
        let result = <IUploadFile><unknown>r;
        return result;
      }).catch(r => this.handleResponse(r));
  }


  private handleResponse(res) {
    console.log(res);
    return throwError(res);
  }
}
