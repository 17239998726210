import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  apiEndpoint: string;
  defaultLanguage: string;
  passwordLength: number;
  storageKeyUser: string;
  storageKeyAvatar: string;
  storageKeyLanguage: string;
  storageApplicationConfiguration: string;
  storageSetupKey: string;
  pageSize: number;
}

export const APP_DI_CONFIG: AppConfig = {
  apiEndpoint: environment.apiEndpoint,
  defaultLanguage: environment.defaultLanguage,
  passwordLength: environment.passwordLength,
  storageKeyUser: "userInfo",
  storageKeyAvatar: "userAvatar",
  storageKeyLanguage: "languageInfo",
  storageApplicationConfiguration: 'appConfig',
  storageSetupKey: 'setupApp',
  pageSize: 10
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }
