import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';
import { APP_CONFIG, AppConfig } from '../../shared/app-config.module';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch'; // don't forget this, or you'll get a runtime error
import * as Enumerable from "linq-es2015";

@Injectable()
export class MetadataService {

  constructor(
    private http: Http,
    @Inject(APP_CONFIG) private config: AppConfig) { }

  getMetadata(objType: number, objNumber: number, language: string, cacheKey: string, standard: boolean): Observable<Response> {
    return this.http.get(this.config.apiEndpoint + '/metadata/entitymetadata/' + objType + '/' + objNumber + '/' + language + '/' + standard)
      .map(r => r.json())
      .catch(r => this.handleResponse(r));
  }

  getPlaceholder(attributes, name) {
    if (attributes) {
      let placeholder = Enumerable.from(attributes).FirstOrDefault(x => (<any>x).logicalName == name);
      if (placeholder)
        return (<any>placeholder).displayName;
    }
    return "";
  };

  private handleResponse(res) {
    //debugger;
    console.log(res);
    return Observable.throw(res);
  }
}
