import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "../../security/auth.service";
import { ValidationService } from '../../shared/validation/validation.service';
import { APP_CONFIG, AppConfig } from '../../shared/app-config.module';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from '../../shared/language/language.service';
import { IApplicationConfiguration } from '../../models/IApplicatinConfiguration';
import { finalize } from 'rxjs/operators';
import { ICompany } from '../../models/ICompany';
import { AlertService } from '../../shared/alert/alert.service';

@Component({
  selector: "login",
  templateUrl: './login.component.html',
  styleUrls: ['./login.style.css']
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = new BehaviorSubject<boolean>(false);
  returnUrl: string;
  loginForm: FormGroup;
  submitted = false;
  passwrodLength: number;
  language: string;
  configuration: IApplicationConfiguration;
  filteredCompanies: ICompany[];
  regexUrl = ".*"  //Needed (ngPattern surrounds with ^ and $)
    + "(?=^.{6,}$)"       //At least 6 Characters
    + "(?=[^\\d]*\\d)"    //At least one digit
    + "(?=[^\\W]*\\W)"    //At least one special character
    + "(?=[^A-Z]*[A-Z])"  //At least one uppercase character
    + "(?=[^a-z]*[a-z])"  //At least one lowercase character
    + ".*";    //Needed (ngPattern surrounds with ^ and $)
  

  constructor(
    private router: Router,
    private authService: AuthService,
    private validationService: ValidationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public languageService: LanguageService,
    @Inject(APP_CONFIG) private config: AppConfig) {

    this.passwrodLength = config.passwordLength;

    var configuration = localStorage.getItem(config.storageApplicationConfiguration);
    if (configuration != null && configuration != "")
      this.configuration = JSON.parse(configuration);
    else
      this.configuration = <IApplicationConfiguration>{};

  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      id: [''],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(this.passwrodLength)]],
      companyName: [this.configuration.companyName, Validators.required],
      serverInstance: [this.configuration.serverInstance, [Validators.required, Validators.pattern(this.regexUrl)]]
    });

    var serverInstance = this.loginForm.controls.serverInstance.value;
    if (serverInstance == undefined || serverInstance == null || serverInstance == "")
    {
      this.loading.next(true);
      this.authService.getSetup().subscribe(
        data => {
          this.loginForm.controls['serverInstance'].setValue(data.base_Url_Web_Service);
          this.loading.next(false);
        },
        error => {
          let obj = JSON.parse(error._body);
          this.validationService.mapValidationServerMessage(obj, this.loginForm);
          this.loading.next(false);
        });
    } 
    this.language = this.languageService.getLanguage();
    this.authService.logout();
  }

  onSubmit(model) {
    this.submitted = true;
    this.loginForm.controls['id'].updateValueAndValidity();
    if (this.loginForm.invalid) {
      return;
    }
    this.login(model);
  }

  login(model) {
    this.loading.next(true);
    this.language = this.languageService.getLanguage();
    this.authService.login(model.controls.username.value, model.controls.password.value, this.language, model.controls.serverInstance.value, model.controls.companyName.value)
      .subscribe(
        data => {
          this.loading.next(false);
          this.router.navigate(['home']);
        },
        error => {
          let obj = JSON.parse(error._body);
          this.validationService.mapValidationServerMessage(obj, this.loginForm);
          this.loading.next(false);
        });
  }

  onChangeServerInstance() {
    this.loginForm.controls.companyName.setValue('');
    this.filteredCompanies = [];
  }

  onFocusCompanyName() {
    this.lookupCompanyName();
  }

  lookupCompanyName() {
    this.alertService.clear();
    this.loading.next(true);
    var serverInstance = this.loginForm.controls.serverInstance.value;
    return this.authService.loadCompanies(serverInstance)
      .pipe(
        finalize(() => {
          this.loading.next(false);
        }))
      .subscribe((results) => {
        this.filteredCompanies = results;
      }, (error) => {
          var err = error.json();
          this.alertService.error(err.message);
          this.loginForm.controls.companyName.setValue('');
          this.filteredCompanies = [];
      });
  }
}
