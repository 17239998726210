import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../shared/app-config.module';
import { ActivatedRoute, Data } from '@angular/router';
import { ITimeSheetLine, TypeLine, UpdateTimeSheetLine } from '../../../models/IMyTimeSheet';
import { TimeSheetService } from '../timesheet.service';
import { FormGroup, FormBuilder, FormArray, } from '@angular/forms';
import { BehaviorSubject, of, Observable } from 'rxjs';
import * as Enumerable from "linq-es2015";
import { AuthService } from '../../../security/auth.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../../../shared/validation/validation.service';
import { LanguageService } from '../../../shared/language/language.service';
import { TimeSheetDetailComponent } from './timesheet.detail.base.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OffsetDatePipe } from '../../../shared/pipes';


@Component({
  selector: 'table-timesheet-detail',
  templateUrl: './table.timesheet.detail.component.html',
  styleUrls: ['../timesheet.component.scss'],
  providers: [OffsetDatePipe]
})

export class TableTimeSheetDetailComponent extends TimeSheetDetailComponent {

  displayedColumnsLines = ['selected', 'line_No', 'type', 'job_No', 'cause_of_Absence_Code', 'job_Task_No', 'work_Type_Code', 'description', 'field1', 'field2', 'field3', 'field4', 'field5', 'total_Quantity', 'status'];
  loading = new BehaviorSubject<boolean>(false);
  currentRow: any;
  errorMsg: string;
  submitted: boolean;


  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public route: ActivatedRoute,
    public timeSheetService: TimeSheetService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public validationService: ValidationService,
    public languageService: LanguageService,
    public dialog: MatDialog,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public offsetDatePipe: OffsetDatePipe) {

    super(route, timeSheetService, snackBar, translateService, validationService, languageService, dialog, authService, fb, sanitizer, config, offsetDatePipe);
  }

  get timeSheetLines() { return (<ITimeSheetLine[]><unknown>this.form.get('timeSheetLines')); }

  updateView() {
    this.dataSourceLines.next((<any>this.form.get('timeSheetLines')).controls);
  }

  addLine() {
    this.timeSheetService.addLine(this.timeSheet.no, TypeLine.Job).subscribe((updateTimeSheetLine: UpdateTimeSheetLine) => {
      if (updateTimeSheetLine) {
        if (!this.timeSheet.timeSheetLineSubForm)
          this.timeSheet.timeSheetLineSubForm = [];
        this.timeSheet.timeSheetLineSubForm.push(updateTimeSheetLine.timeSheetLineSubForm);
        this.timeSheet.timeSheetDetailLine.push(updateTimeSheetLine.timeSheetDetailLine[0]);
        this.addRow(updateTimeSheetLine.timeSheetLineSubForm, this.timeSheet.timeSheetLineSubForm.length, false);
      }
    });
  }

  addRow(line?: ITimeSheetLine, index?: number, noUpdate?: boolean) {

    let values: number[] = [];
    values = this.getQuantity(line);
    let row = this.fb.group({
      'selected': [line && line.selected ? line.selected : null, []],
      'key': [line && line.key ? line.key : null, []],
      'time_Sheet_No': [line && line.time_Sheet_No ? line.time_Sheet_No : this.timeSheet.no, []],
      'line_No': [line && line.line_No ? line.line_No : 0, []],
      'job_No': [line && line.job_No ? line.job_No : null, []],
      'job_Task_No': [line && line.job_Task_No ? line.job_Task_No : null, []],
      'description': [line && line.description ? line.description : null, []],
      'type': [line && line.type ? line.type : null, []],
      'field1': [values && values[0] ? values[0] : null, []],
      'field2': [values && values[1] ? values[1] : null, []],
      'field3': [values && values[2] ? values[2] : null, []],
      'field4': [values && values[3] ? values[3] : null, []],
      'field5': [values && values[4] ? values[4] : null, []],
      'total_Quantity': [line && line.total_Quantity ? line.total_Quantity : null, []],
      'status': [line && line.status ? line.status : null, []],
      'filteredJobs': [[], []],
      'filteredJobTasks': [[], []]
    });
    this.timeSheetLines.push(<ITimeSheetLine><unknown>row);

    if (!noUpdate) {
      this.updateView();
    }
  }

  onChangeRow(row: FormGroup) {
    let l = <ITimeSheetLine>row.value;
    let line = Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x => x.time_Sheet_No == l.time_Sheet_No && x.line_No == l.line_No);
    if (line) {
      line.job_No = l.job_No;
      line.job_Task_No = l.job_Task_No;
      line.type = TypeLine.Job;
    } else {
      let newLineNo = 10000;
      let last = Enumerable.from(this.timeSheet.timeSheetLineSubForm).OrderBy(x => x.line_No).LastOrDefault(x => x.time_Sheet_No == l.time_Sheet_No);
      if (last != null)
        newLineNo += 10000;
      let newLine: ITimeSheetLine = {
        time_Sheet_No: this.timeSheet.no,
        job_No: l.job_No,
        job_Task_No: l.job_Task_No,
        line_No: newLineNo,
        type: TypeLine.Job,
        resource_No: this.timeSheet.resource_No
      };
      this.timeSheet.timeSheetDetailLine.push(newLine);
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  prevRecord() {
    this.timeSheetService.getPrevRecord(this.timeSheet.no, this.timeSheet.resource_No).subscribe(r => {
      if (r) {
        this.timeSheet = r;
        this.setColumns();
        this.form = this.fb.group({ 'timeSheetLines': this.fb.array([]) });
        this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine, i: number) => this.addRow(d, i, true));
        this.updateView();
      }
    });
  }

  nextRecord() {
    this.timeSheetService.getNextRecord(this.timeSheet.no, this.timeSheet.resource_No).subscribe(r => {
      if (r) {
        this.timeSheet = r;
        this.setColumns();
        this.form = this.fb.group({ 'timeSheetLines': this.fb.array([]) });
        this.timeSheet.timeSheetLineSubForm.forEach((d: ITimeSheetLine, i: number) => this.addRow(d, i, true));
        this.updateView();
      }
    });
  }

}
