import { Component, Inject, OnInit} from "@angular/core";
import { APP_CONFIG, AppConfig } from "../../shared/app-config.module";
import { AuthService } from "../../security/auth.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { IProfile } from "../../models/IProfile";
import { UserService } from "../../security/user.service";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from "@angular/platform-browser";
import { BroadcastService } from "../../shared/broadcast.service";
import { ValidationService } from "../../shared/validation/validation.service";
import { IValidationMessage } from "../../models/IValidationMessage";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material";
import { IUser } from "../../models/IUser";

@Component({
  selector: "profile",
  templateUrl: './profile.component.html',
  styleUrls:["./profile.style.css"]
})
export class ProfileComponent implements OnInit {
  profile: any = <IProfile>{};
  form: FormGroup = this.fb.group({});
  loading = new BehaviorSubject<boolean>(false);
  uploader: FileUploader;
  hasDragOver = false;
  editmode = true;
  avatar: any = "";
  submitted: boolean = false;
  
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private broadcastService: BroadcastService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private validationService: ValidationService,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,) {

    this.uploader = new FileUploader({
      url: config.apiEndpoint + "/user/uploadavatar",
      headers: [
        { name: "Authorization", value: "Bearer " + this.authService.token },
        { name: "Access-Control-Allow-Credentials", value: "true" }
      ],
      disableMultipart: false,
      autoUpload: true
    });

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.response.subscribe((result) => {
      sessionStorage.removeItem(this.config.storageKeyAvatar);
      this.userService.getAvatar(this.authService.user.username).subscribe((result) => {
        if (result != undefined && result != null && result != "")
          this.avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + result);
        else
          this.avatar = null;
        this.broadcastService.boradcast("AVATAR-CHANGE", this.avatar);
      });
    });

  }

  ngOnInit(): void {
    this.loading.next(true);
    this.getAvatar();
    this.userService.getProfile()
      .pipe(finalize(() => {
        this.loading.next(false);
      }))
      .subscribe((result) => {
        this.profile = result;
      });
  }

  fileOver(e: any): void {
    this.hasDragOver = e;
  }

  getAvatar() {
    this.userService.getAvatar(this.authService.user.username).subscribe((result) => {
      if (result != undefined && result != null && result != "")
        this.avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + result);
      else
        this.avatar = null;
    });
  }

  clearAvatar() {
    this.userService.clearAvatar().subscribe((result) => {
      this.getAvatar();
    });
  }

  save() {
    this.submitted = true;
    this.validationService.clearValidationMessage();
    this.loading.next(true);
    this.userService.updateAvatar(this.profile).subscribe((r: IProfile) => {
      this.showMessage(this.translateService.instant('SAVE_SUCCESS_MESSAGE'), '');
      var userStore = JSON.parse(sessionStorage.getItem(this.config.storageKeyUser));
      var user = (<IUser>userStore.user);
      user.email = r.e_Mail;
      user.fullName = r.full_Name;
      user.viewMode = r.view_Mode;
      userStore.user = user;
      var d = JSON.stringify(userStore);
      sessionStorage.setItem(this.config.storageKeyUser, d);
    }, error => {
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => {
      this.loading.next(false);
    });
  }

  private showMessage(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['mat-toolbar', 'mat-accent'],
      direction: "ltr",
      horizontalPosition: "center",
    });
  }
}
