export interface IProfile {
  key: string;
  resource_No: string;
  full_Name: string;
  username: string;
  e_Mail: string;
  password: string;
  language: string;
  activation_Sent: boolean;
  status: Status;
  first_Login: boolean;
  first_Login_Date: Date;
  resource_Job_Title: string;
  view_Mode: ViewMode;
}

export enum Status {
  _blank_,
  Enable,
  Disable,
}

export enum ViewMode {
  Default,
  Week
}
